//
// Section Hero
//

.section-hero {
  //display: table;
  //table-layout: fixed;
  //height: 430px;
  width: 100%;
  background-color: $white;
  position: relative;

  .hero-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 430px;
  }

  h1 {
    font-size: 64px;
    line-height: 1;

    @include media-breakpoint-down(sm) {
      font-size: 32px;
    }
  }

  p {
    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  .page-home &{
    height: 100vh;
    min-height: 100%;
  }

  /*@include media-breakpoint-down(sm) {
    nav {
      background-color: rgba($navbar-bg, 0.8);
    }
  }*/
}

.section-hero-inner{
  position: relative;
  height: 100%;
  //display: table-cell;
  //vertical-align: top;

  @include media-breakpoint-up(lg){
    //vertical-align: middle;
  }

  > .container {
    padding-top: 130px;
    padding-bottom: 50px;

    @include media-breakpoint-down(lg) {
      padding-top: 100px;
    }

    @include media-breakpoint-down(md) {
      padding-top: 60px;
    }

    @include media-breakpoint-down(md) {
      padding-top: 0;
    }
  }
}

.section-hero-header{
  @include media-breakpoint-up(lg){
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  @if $brand == 'tripsta'{
    > .navbar{
      background-color: rgba($navbar-bg, .3);
    }
  }
}

.section-hero-slider{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .slick-list,
  .slick-track,
  .slick-slide{
    height: 100%;
  }
}


// Section header

.section-header{
  display: flex;
  align-items: center;
  padding-bottom: theme-spaces(2);
  margin-bottom: theme-spaces(3);
  border-bottom: 1px solid $border-color;

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0px;
  }
}

// Section footer

.section-footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: theme-spaces(2);
  border-top: 1px solid $border-color;
}

// Cars Landing page

.cars-hero,
.hotels-hero{
  position: relative;
  height: 800px;
  
  @include media-breakpoint-up(md){
    height: 640px;
  }

  > .bg-overlay{
    background-color: rgba($gray-dark, 0.4);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.carsSearchMask,
.hotelSearchMask{
  iframe{
    left: 50%;
    transform: translateX(-50%);
  }
}

.carsSearchMask{
  iframe{
    max-width: 1144px;
  }
}

.hotelSearchMask{
  iframe{
    max-width: 987px;
  }
}

.full-page {
  height: 100vh;
}

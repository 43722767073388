//
// Waiting Screen
//
.loader-line-mask {
  position: absolute;
  width: 50px;
  height: 45px;
  overflow: hidden;
  margin-top: -2px;
  transform-origin: 25px 25px;
  -webkit-mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
  animation: rotate .8s infinite linear;
}

.loader-line {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 3px type-color('flight');
}

.loader-flights {
    background: url('#{$img-path}/#{$brand}/ic_flight_brand_waiting.svg') no-repeat center;
    position: absolute;
    background-size: 24px;
    width: 50px;
    height: 50px;
    margin-top: -2px;
    animation: fade-flights 2s infinite;
}
@keyframes fade-flights {
    0%      {opacity:1}
    50%     {opacity:0}
    100%    {opacity:1}
}

.loader-ads {
  max-width: 728px;
  height: 90px;
  margin-left: auto;
  margin-right: auto;
}


//
// Multimodal Animation Waiting Screen
//

.loader-allmeans-flights {
    background: url('#{$img-path}/#{$brand}/ic_flight_brand_waiting.svg') no-repeat center;
    position: absolute;
    background-size: 24px;
    width: 50px;
    height: 50px;
    animation:fade-allmeans-flights 8s infinite;
    -webkit-animation:fade-allmeans-flights 8s infinite;
}

.loader-allmeans-ferries {
    background: url('#{$img-path}/#{$brand}/ic_ferries_brand_waiting.svg') no-repeat center;
    position: absolute;
    background-size: 24px;
    width: 50px;
    height: 50px;
    animation:fade-allmeans-ferries 8s infinite;
    -webkit-animation:fade-allmeans-ferries 8s infinite;
}

.loader-allmeans-trains {
    background: url('#{$img-path}/#{$brand}/ic_rails_brand_waiting.svg') no-repeat center;
    position: absolute;
    background-size: 24px;
    width: 50px;
    height: 50px;
    animation:fade-allmeans-trains 8s infinite;
    -webkit-animation:fade-allmeans-trains 8s infinite;
}

.loader-allmeans-buses {
    background: url('#{$img-path}/#{$brand}/ic_bus_brand_waiting.svg') no-repeat center;
    position: absolute;
    background-size: 24px;
    width: 50px;
    height: 50px;
    animation:fade-allmeans-buses 8s infinite;
    -webkit-animation:fade-allmeans-buses 8s infinite;
}

@keyframes fade-allmeans-flights {
    0%      {opacity:1}
    12.5%   {opacity:0}
    25%     {opacity:0}
    37.5%   {opacity:0}
    50%     {opacity:0}
    62.5%   {opacity:0}
    75%     {opacity:0}
    87.5%   {opacity:0}
    100%    {opacity:1}
}
@keyframes fade-allmeans-ferries {
    0%      {opacity:0}
    12.5%   {opacity:0}
    25%     {opacity:1}
    37.5%   {opacity:0}
    50%     {opacity:0}
    62.5%   {opacity:0}
    75%     {opacity:0}
    87.5%   {opacity:0}
    100%    {opacity:0}
}
@keyframes fade-allmeans-trains  {
    0%      {opacity:0}
    12.5%   {opacity:0}
    25%     {opacity:0}
    37.5%   {opacity:0}
    50%     {opacity:1}
    62.5%   {opacity:0}
    75%     {opacity:0}
    87.5%   {opacity:0}
    100%    {opacity:0}
}
@keyframes fade-allmeans-buses   {
    0%      {opacity:0}
    12.5%   {opacity:0}
    25%     {opacity:0}
    37.5%   {opacity:0}
    50%     {opacity:0}
    62.5%   {opacity:0}
    75%     {opacity:1}
    87.5%   {opacity:0}
    100%    {opacity:0}
}

#tabsOverlay {
    &:hover {
        cursor: not-allowed;
    }
    width: 100%;
    height: 105%;
    position: absolute;
    z-index: 100;
} // when results are loading this overlay goes over the recent search to prevent click

//
// Border
//

.border          { border: 1px solid $border-color !important; }
.border-top      { border-top: 1px solid $border-color !important; }
.border-right    { border-right: 1px solid $border-color !important; }
.border-bottom   { border-bottom: 1px solid $border-color !important; }
.border-left     { border-left: 1px solid $border-color !important; }

.border-0        { border: 0 !important; }
.border-top-0    { border-top: 0 !important; }
.border-bottom-0 { border-bottom: 0 !important; }
.border-right-0  { 
  @if $text-direction == ltr {
    border-right: 0 !important;
  } @else {
    border-left: 0 !important;
  }
}
.border-left-0   { 
  @if $text-direction == ltr {
    border-left: 0 !important;
  } @else {
    border-right: 0 !important;
  }
}

@each $prop in (top, bottom, right, left){
  @each $style in (dotted, dashed, solid){
    .border-#{$prop}-#{$style} { border-#{$prop}: 1px $style $border-color !important; }
  }
}
@each $prop in (top, bottom, right, left){
  @each $style in (dotted, dashed, solid){
    .two-border-#{$prop}-#{$style} { border-#{$prop}: 2px $style $border-color !important; }
  }
}


// Border Color

@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}

// Border Style

.border-dashed  { border-style: dashed !important; }
.border-dotted  { border-style: dotted !important; }


// Border color

.border-white {
  border-color: $white !important;
}


// Border-radius

.border-radius-lg {
  border-radius: $border-radius-lg;
}
.border-radius-lg-left {
  @if $text-direction == ltr {
    border-top-left-radius: $border-radius-lg !important;
    border-bottom-left-radius: $border-radius-lg !important;
  } @else {
    border-top-right-radius: $border-radius-lg !important;
    border-bottom-right-radius: $border-radius-lg !important;
  }
}
.border-radius-lg-right {
  @if $text-direction == ltr {
    border-top-right-radius: $border-radius-lg !important;
    border-bottom-right-radius: $border-radius-lg !important;
  } @else {
    border-top-left-radius: $border-radius-lg !important;
    border-bottom-left-radius: $border-radius-lg !important;
  }
}
.border-radius-lg-top {
  border-top-left-radius: $border-radius-lg !important;
  border-top-right-radius: $border-radius-lg !important;
}
.border-radius-lg-bottom {
  border-bottom-right-radius: $border-radius-lg !important;
  border-bottom-left-radius: $border-radius-lg !important;
}
.border-radius-lg-top-left {
  border-top-left-radius: $border-radius-lg !important;
}
.border-radius-lg-top-right {
  border-top-right-radius: $border-radius-lg !important;
}
.border-radius-lg-bottom-left {
  border-bottom-left-radius: $border-radius-lg !important;
}
.border-radius-lg-bottom-right {
  border-bottom-right-radius: $border-radius-lg !important;
}
.rounded {
  border-radius: $border-radius !important;
}
.rounded-logo {
  border-radius: 6px;
  border: 1px solid $gray-light;
}
.rounded-top {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
}
.rounded-right {
  @if $text-direction == ltr {
    border-top-right-radius: $border-radius !important;
    border-bottom-right-radius: $border-radius !important;
  } @else {
    border-top-left-radius: $border-radius !important;
    border-bottom-left-radius: $border-radius !important;
  }
}
.rounded-bottom {
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}
.rounded-left {
  @if $text-direction == ltr {
    border-top-left-radius: $border-radius !important;
    border-bottom-left-radius: $border-radius !important;
  } @else {
    border-top-right-radius: $border-radius !important;
    border-bottom-right-radius: $border-radius !important;
  }
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

// Reset Border radius

.rounded-right-0 {
  @if $text-direction == ltr {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  } @else {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.rounded-left-0 {
  @if $text-direction == ltr {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  } @else {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-pill {
  border-radius: 50rem!important;
}

//
// Utilities for common `display` values
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .visibility#{$infix}-hidden   { visibility: hidden !important; }
    .visibility#{$infix}-visible  { visibility: visible !important; }
  }
}
.leaflet-control-attribution.leaflet-control {
  display: none;
}

.leaflet-control-zoom.leaflet-control {
  border: 0;

  &.leaflet-bar {

    a {
      border: 0;
      border-radius: $border-radius;
      box-shadow: 0 2px 10px rgba($gray-dark, 0.25);
      color: $primary;
      text-decoration: none;

      &.leaflet-control-zoom-in {
        margin-bottom: 8px;
      }
    }
  }
}

// 
// Opacity Effect
// 

.opacity-min { opacity: 0.8; }
.opacity-mid { opacity: 0.6; }
.opacity-max { opacity: 0.4; }

.opacity-hover{
  cursor: pointer;
  transition: $transition-base;

  @include hover-focus{
    opacity: 1;
  }
}

// 
// Grayscale Effect
// 

.grayscale-min { filter: grayscale(50%); }
.grayscale-mid { filter: grayscale(75%); }
.grayscale-max { filter: grayscale(100%); }

.grayscale-hover{
  cursor: pointer;
  transition: $transition-base;

  @include hover-focus{
    filter: grayscale(0);
  }
}
$select-input-height:   $input-height;

// @import '~react-select-plus/scss/default';

.Select-option-group-label {
  box-sizing: border-box;
  background-color: #fff;
  color: $body-color;
  font-weight: bold;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.Select-control{
  border-color: $input-border-color;
  .has-error &{
    border-color: theme-color('danger');
  }
}
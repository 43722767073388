.checkout-widget{
  margin-bottom: theme-spaces(4);

  .section-footer{
    margin-top: theme-spaces(4);
    padding-bottom: theme-spaces(3);
  }
}

.checkout-steps-header{
  display: flex;
  font-size: 22px;
  line-height: $headings-line-height;
}

// checkout steps

.checkout-steps-header-indicator{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  //color: $white;
  //background-color: $secondary;
  margin-top: calc(theme-spaces(1) / 2);
  @if $text-direction == ltr {
    margin-right: theme-spaces(1);
  } @else {
    margin-left: theme-spaces(1);
  }

  .is-closed &{
    color: $success;
    background-color: $success;

    &:before{
      content: '\2713';
      color: $white;
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
    }
  }
}


// Checkout terms

.checkout-terms{
  max-height: 150px;
  overflow-y: auto;
}

// Passengers loader container

#partner-wait-loader{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  flex-direction: column;
}


// Fix Basket on scroll

#basketDetails.affix{
  @include media-breakpoint-up(lg){
    position: fixed;
    top: 0;
    height: 100%;
    @if $text-direction == ltr {
    padding-right: theme-spaces(3);
    } @else {
      padding-left: theme-spaces(3);
    }
    width: calc((960px * .3) - (#{$grid-gutter-width}/2));
  }
  @include media-breakpoint-up(xl){
    width: calc((1144px * .3) - (#{$grid-gutter-width}/2));
  }
}

.page-ferry_payment {
  .bg-credit-card {
    background-color: lighten($info, 20%);
  }
  .header-cc {
    .section-header-checkout {
      background-color: lighten($info, 20%);
    }

    .slick-slider.clip {
      .slick-list {
        overflow-x: clip;
        overflow-y: visible;
      }
    }
  }

  .bank-card {
    height: 100px;
    background-color: $white;
    cursor: pointer;

    &.selected {
      border: 2px solid $success;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.6;
      background-color: rgba($gray-mid, 0.6);
    }

    &:not(.disabled) {
      @include hover {
        box-shadow: 0px 2px 10px rgba(53, 62, 68, 0.25);
      }
    }
  }

  // custom position for pci
  .pciPosition {
    position: relative;

    @include media-breakpoint-up(md) {
      position: absolute;
      right: theme-spaces(3);
      bottom: theme-spaces(3);
    }
  }
}

.ticket-pickup {
  .react-tabs {
    .delivery-tabs {
      > div {
        display: inline-block;

        @include media-breakpoint-down(sm) {
          width: 100%;
          text-align: center;
        }
      }
    }

    .inset-shadow {
      .nav-item:not(.active) {
        .nav-link {
          box-shadow: 0px -4px 10px -1px rgba($gray-dark, 0.25) inset;
        }
      }
    }

    .nav-item {
      background-color: $gray-light;

      @include media-breakpoint-down(sm) {
        width: 50%;
        text-align: center;
      }

      &:first-child {
        border-radius: $border-radius 0 0 0;
      }

      &:last-child {
        border-radius: 0 $border-radius 0 0;

        .nav-link {
          border-radius: 0 $border-radius 0 0;
        }
      }

      .nav-link {
        > span {
          display: inline-block;
          padding: 1px 5px;
          font-size: 10px;
          background-color: $accent;
          color: $white;
          border-radius: 8px;
          margin-left: 4px;
        }
      }

      &.active {
        border-radius: $border-radius $border-radius 0 0;
        position: relative;
        z-index: 1;

        &:first-child {
          .nav-link {
            border-radius: $border-radius $border-radius 0 0;
          }
        }

        &:last-child {
          .nav-link {
            border-radius: $border-radius $border-radius 0 0;
          }
        }

        > .nav-link {
          &:not(.disabled) {
            color: $nav-link-active-color;
            background-color: $nav-tabs-link-active-bg;
          }
        }
      }

      &:not(.active) {
        &:first-child {
          .nav-link {
            border-radius: $border-radius 0 0 0;
          }
        }
      }

      .nav-link {
        border: $nav-tabs-border-width solid transparent;
        background-color: $gray-light;

        &.disabled {
          color: $nav-link-disabled-color;
          background-color: transparent;
          border-color: transparent;
        }
      }
    }

    .tab-content {
      border-radius: 0 $border-radius $border-radius $border-radius;

      &.no-tabs {
        box-shadow: unset;
        border-top: 1px solid $border-color;
      }
    }
  }
}

.nav-pills-payment{
  .nav-item.active > .nav-link:not(.disabled){
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: $primary;
  }
}

.tab-content-payment{
  background-color: $primary;
  color: $white;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: $border-radius;

  @include media-breakpoint-up(md){
    @if $text-direction == ltr {
      padding-left: 28px;
      margin-left: -28px;
      @include border-left-radius(0);
    } @else {
      padding-right: 28px;
      margin-right: -28px;
      @include border-right-radius(0);
    }
  }

  a {
    color: $white;
    font-weight: bold;

    @include hover{
      color: inherit;
      text-decoration: underline;
    }
  }
}

// Breadcrump active step

.steps.active .step-circle{
  background-color: $primary;
}

.seperator-circle {
  background-color: $body-bg;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: absolute;
}
$sp-table-border-width: 1px;

.service-package-table {
  width: 100%;
  max-width: 100%;
  margin: theme-spaces(4) 0 theme-spaces(1);
  border-collapse: separate;
  border-spacing: 0;

  th,
  td {
    vertical-align: middle;
    word-break: break-word;
    max-width: 0;

    &:first-child {
      width: 34%;
      border-width: $sp-table-border-width 0 0 $sp-table-border-width;
      border-style: solid;
      border-color: $table-border-color;
    }

    &:not(:first-child) {
      cursor: pointer;
      text-align: center;
      border-width: $sp-table-border-width 0 0 $sp-table-border-width;
      border-style: solid;
      border-color: $table-border-color;
      background-color: $white;
    }
  }

  tr {
    &:last-child {
      td {
        line-height: 1.2;
      }
    }
  }

  th {
    line-height: 1.2;
    padding-top: 22px;

    > .best-value-label {
      line-height: 2;
    }
  }

  tbody tr:nth-of-type(odd) td {
    background-color: lighten($gray-light, 4%);
  }

  tbody tr:nth-last-child(2) td {
    border-bottom: $sp-table-border-width solid $table-border-color;
  }

  tbody tr:last-child td {
    background-color: transparent;
    border: none;
  }

  tbody tr:first-child td {
    background-color: transparent;
    border-top: 0;
  }

  thead tr th:not(:first-child) {
    position: relative;
    border-bottom: 0;
  }

  tbody tr:first-child td:first-child, thead tr:first-child th:first-child {
    border: 0;
  }

  tbody td:last-child, thead th:last-child {
    border-right: $sp-table-border-width solid $table-border-color;

    &.recommended {
      border-left: $sp-table-border-width solid $success;
      border-right: $sp-table-border-width solid $success;
    }
  }

  tbody tr:nth-last-child(2) td:last-child {
    &.recommended {
      border-bottom: $sp-table-border-width solid $success;
    }
  }

}

// Service Package features
.sp-feature-item{
  padding-bottom: calc(theme-spaces(4) / 2);
  position: relative;
  
  &:not(.has-text){
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.has-text{
    @if $text-direction == ltr {
      padding-left: 20px;
    } @else {
      padding-right: 20px;
    }

    &:before{
      content: "\ea53";
      font-family: Nucleo;
      color: $success;
      position: absolute;
      top: 0;
      @if $text-direction == ltr {
        left: 0;
      } @else {
        right: 0;
      }
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

.sp-feature-desc{
  position: relative;
  cursor: help;
  flex: 1;
  padding: 8px;

  &.no-desc {
    cursor: default;
  }

  span{
    border-bottom: 1px dotted;
    @if $text-direction == ltr {
      margin-right: 16px;
    } @else {
      margin-left: 16px;
    }
  }

  .icon-c-info{
    width: 16px;
    height: 16px;
    text-align: center;
    position: absolute;
    top: 4px;
    @if $text-direction == ltr {
      right: 4px;
    } @else {
      left: 4px;
    }
  }
}
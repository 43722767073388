/* --------------------------------

Nucleo icon font
Generated using nucleoapp.com

-------------------------------- */
@import "_nucleo-variables";

// MOVED FONT DECLARATION TO _head.html.erb SO WE CAN USE ENVIRONMENT GENERATED PATH TO FONT
//@font-face {
//  font-family: 'Nucleo';
//  font-display: auto;
//  //src: url('#{$icon-font-path}/Nucleo.woff2') format('woff2'),
//  //  url('#{$icon-font-path}/Nucleo.woff') format('woff');
//  src: url('http://assets.frontline/fonts/nucleo/Nucleo.woff2') format('woff2'),
//    url('http://assets.frontline/fonts/nucleo/Nucleo.woff') format('woff');
//    //url('#{$icon-font-path}/Nucleo.ttf') format('truetype'),
//    //url('#{$icon-font-path}/Nucleo.svg') format('svg'),
//    //url('#{$icon-font-path}/Nucleo.eot') format('embedded-opentype');
//  font-weight: normal;
//  font-style: normal;
//}

/*------------------------
  base class definition
-------------------------*/

.icon {
  display: inline-block;
  font: normal normal normal 1em/1 'Nucleo';
  color: inherit;
  flex-shrink: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------------------------
  change icon size
-------------------------*/

/* relative units */
.icon-sm {
  font-size: 0.8em;
}
.icon-lg {
  font-size: 1.2em;
}
/* absolute units */

$icon-units: (
  10: 10px,
  16: 16px,
  24: 24px,
  32: 32px
);

@each $name, $type in $icon-units {
  .icon-#{$name} {
    font-size: $type;
  }
}

/*----------------------------------
  add a square/circle background
-----------------------------------*/

.icon-bg-square,
.icon-bg-circle {
  padding: 0.35em;
  background-color: $icon-background-color;
}

.icon-bg-circle {
  border-radius: 50%;
}

/*------------------------------------
  use icons as list item markers
-------------------------------------*/

.icon-ul {
  padding-left: 0;
  list-style-type: none;

  > li {
    display: flex;
    align-items: flex-start;
    line-height: 1.4;
  }

  > li > .icon {
    margin-right: $icon-li-margin-right;
    line-height: inherit;
  }
}

/*------------------------
  spinning icons
-------------------------*/

/* rotate the icon infinitely */
.icon-is-spinning {
  animation: icon-spin 1s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*------------------------
  rotated/flipped icons
-------------------------*/

/* transform */
.icon-rotate-90  {
  transform: rotate(90deg);
}

.icon-rotate-180 {
  transform: rotate(180deg);
}

.icon-rotate-270 {
  transform: rotate(270deg);
}

.icon-flip-y {
  transform: scaleY(-1);
}

.icon-flip-x {
  transform: scaleX(-1);
}

@import "_nucleo-icons";

.twitter-typeahead {
	width: 100%;
}

.tt-menu{
	width: 100%;
	margin-top: theme-spaces(1);
}

.tt-suggestion{
	position: relative;
  display: block;
  color: $body-color;
	padding: theme-spaces(2) theme-spaces(3);
  margin-bottom: -1px;
  background-color: $white;
  border: 1px solid $border-color;

  &:first-child {
    @include border-top-radius($border-radius);
  }

  &:last-child {
    margin-bottom: 0;
    @include border-bottom-radius($border-radius);
  }

  @include hover-focus {
  	cursor: pointer;
    text-decoration: none;
    background-color: $component-hover-color;
  }
}

.tt-cursor{
  background-color: $component-hover-color;
}

.tt-suggestion{
	.airport-code{
		font-size: $font-size-sm;
		@if $text-direction == ltr {
      float: right;
    } @else {
      float: left;
    }
	}

	.airport-name{
		@if $text-direction == ltr {
      padding-right: theme-spaces(6);
    } @else {
      padding-left: theme-spaces(6);
    }
    @include text-truncate;
	}

	.airport-country{
		font-size: $font-size-sm;
		line-height: $line-height-computed;
		color: $text-muted;
	}
}
//
// Base styles
//

.progress {
  display: flex;
  height: .5rem;
  overflow: hidden; // force rounded corners by cropping it
  font-size: ($font-size-base * .75);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: $border-radius;

  .page-results &{
    margin-bottom: $spacer;
  }

  &.hide {
    opacity: 0;
    height: 0;
    margin: 0;
    @include transition(all .6s ease .6s);
  }
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $white;
  text-align: center;
  white-space: nowrap;
  background-color: $secondary;
  @include transition(width .6s ease);
}
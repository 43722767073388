@mixin caret($size: 3px, $color: #ccc, $direction: "down") {
  width: 0;
  height: 0;

  @if $direction == "down" {

    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;

  } @else if $direction == "up" {

    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;

  } @else if $direction == "left" {

    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right:$size solid $color;

  } @else if $direction == "right" {

    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;

  }
}

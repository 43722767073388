@mixin alert-variant($background, $border, $color) {
  color: $color;
  @if $text-direction == ltr {
    border-left-color: $border;
  } @else {
    border-right-color: $border;
  }

  &:before{
    color: $border;
  }

  .alert-link {
    color: darken($color, 10%);
  }
}
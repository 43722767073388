//
// Base styles
//

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  color: $body-color;
  min-width: 0;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: 1px solid $card-border-color;
  border-radius: $card-border-radius;
  margin-bottom: $card-margin;
  
  &.card-shadow{
    border: 0;
    box-shadow: 0 2px 5px rgba(0,0,0,0.08);
  }
  
  &.card-hover{
    transition: $transition-base;
    
    @include hover{
      box-shadow: 0 5px 10px rgba(0,0,0,0.16);
      cursor: pointer;
      text-decoration: none;
    }
  }
  

  > .list-group:first-child {
    .list-group-item:first-child {
      @include border-top-radius($card-border-radius);
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      @include border-bottom-radius($card-border-radius);
    }
  }
}

.card-block {
  flex: 1 1 auto;
  padding: $card-spacer-y $card-spacer-x;

  > *:last-child {
    margin-bottom: 0;
  }
}

.card-title{
  font-size: $font-size-lg;
  line-height: $line-height-computed;
  font-weight: $font-weight-semibold;
  padding: theme-spaces(2) theme-spaces(3);
  margin: 0;
  border-top: 2px solid $card-border-color;
}

.card-subtitle{
  font-size: $font-size-sm;
  line-height: $line-height-computed;
  margin: 0;
}

// Card Link

.card-link-overlay{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

// Card image caps

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-spacer-x;
}

.card-img{
  width: 100%;
  border-radius: $card-border-radius;
}

.card-img-top{
  width: 100%;
  @include border-top-radius($card-border-radius);
}

.card-img-bottom{
  width: 100%;
  @include border-bottom-radius($card-border-radius);
}

.card-img-pos {
  left: 0;
  bottom: 0;
}

//
// Optional textual caps
//

.card-header {
  padding: (theme-spaces(4) - 4) $card-spacer-x;
  border-bottom: 1px solid $card-border-color;
  margin-bottom: 0;
  color: $headings-color;

  .card-title{
    margin-bottom: 0;
  }

  &:first-child {
    @include border-top-radius($card-border-radius);
  }

  + .list-group {
    .list-group-item:first-child {
      border-top: 0;
    }
  }
}


.card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: calc($card-spacer-y / 2) theme-spaces(3);
  border-top: 1px solid $card-border-color;

  &.bg-light{
    border-color: #ddd;
  }

  &:last-child {
    @include border-bottom-radius($card-border-radius);
  }
}


//
// Card groups
//

.card-group {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored
      flex: 1 0 0%;

      + .card {
        @if $text-direction == ltr {
          margin-left: 0;
          border-left: 0;
        } @else {
          margin-right: 0;
          border-right: 0;
        }
      }

      // Handle rounded corners
      &:first-child {
        @if $text-direction == ltr {
          @include border-right-radius(0);
        } @else {
          @include border-left-radius(0);
        }

        .card-img-top,
        .card-header {
          border-top-right-radius: 0;
        }
        .card-img-bottom,
        .card-footer {
          border-bottom-right-radius: 0;
        }
      }

      &:last-child {
        @if $text-direction == ltr {
          @include border-left-radius(0);
        } @else {
          @include border-right-radius(0);
        }

        .card-img-top,
        .card-header {
          @if $text-direction == ltr {
            border-top-left-radius: 0;
          } @else {
            border-top-right-radius: 0;
          }
        }
        .card-img-bottom,
        .card-footer {
          @if $text-direction == ltr {
            border-bottom-left-radius: 0;
          } @else {
            border-bottom-right-radius: 0;
          }
        }
      }

      &:only-child {
        @include border-radius($card-border-radius);

        .card-img-top,
        .card-header {
          @include border-top-radius($card-border-radius);
        }
        .card-img-bottom,
        .card-footer {
          @include border-bottom-radius($card-border-radius);
        }
      }

      &:not(:first-child):not(:last-child):not(:only-child) {
        @include border-radius(0);

        .card-img-top,
        .card-img-bottom,
        .card-header,
        .card-footer {
          @include border-radius(0);
        }
      }
    }
  }
}


// Card deck

.card-deck {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    margin-right: calc($card-margin / -2);
    margin-left: calc($card-margin / -2);

    .card {
      flex: 1 0 0%;
      // flex: 0 0 calc(50% - #{$card-margin});
      // max-width: calc(50% - #{$card-margin});
      margin-right: calc($card-margin / 2);
      margin-left: calc($card-margin / 2);
    }
  }
}

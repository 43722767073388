// Contextual backgrounds

@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
    color: color-yiq($color);

    hr{
      border-top-color: rgba(color-yiq($color), .1);
    }
  }

  a#{$parent} {
    @include hover-focus {
      background-color: darken($color, 10%) !important;
    }
  }
}
// Breadcrump
.steps-container {
  line-height: unset !important;

  .steps {
    .step-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      color: $white;
      background-color: $ferries;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .steps.disable .step-circle {
    background-color: darken($gray-light, 10%);
  }
}
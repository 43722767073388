//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;

  > li {
    display: inline; // Remove list-style and block-level defaults
    > a,
    > span {
      border-radius: 4px;
      position: relative;
      text-decoration: none;
      color: $primary;
      background-color: #fff;
      width: 24px;
      height: 24px;
      display: inline-block;
      margin: 0px 2px;
    }
    &:first-child {
      > a,
      > span {
        margin-left: 0;
      }
    }
    &:last-child {
      > a,
      > span {
        margin-right: 0;
      }
    }
  }

  > li > a,
  > li > span {
    &:hover,
    &:focus {
      color: $link-color;
      background-color: #eee;
      border-color: #ddd;
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
      z-index: 2;
      color: #fff;
      background-color: $primary;
      border-color: $primary;
      cursor: default;
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
      color: #eee;
      background-color: #fff;
      border-color:  #ddd;
      cursor: not-allowed;
    }
  }
}
/*------------------------
  icons
-------------------------*/
$icons: (
  'clock': 'ea02',
  'tiktok': 'ea03',
  'info': 'ea04',
  'c-info-e': 'ea05',
  'c-info': 'ea06',
  'trash-can': 'ea07',
  'trash-can-1': 'ea08',
  'up-arrow': 'ea09',
  'left-arrow': 'ea0a',
  'down-arrow': 'ea0b',
  'right-arrow': 'ea0c',
  'nav-down': 'ea0d',
  'nav-left': 'ea0e',
  'nav-up': 'ea0f',
  'nav-right': 'ea10',
  'down-arrow-1': 'ea11',
  'left-arrow-1': 'ea12',
  'right-arrow-1': 'ea13',
  'up-arrow-1': 'ea14',
  'document': 'ea15',
  'document-2': 'ea16',
  'paper': 'ea17',
  'file-text': 'ea18',
  'question-mark': 'ea19',
  'c-question-e': 'ea1a',
  'data-upload': 'ea1b',
  'cloud-upload': 'ea1c',
  'cloud-upload-1': 'ea1d',
  'zoom': 'ea1e',
  'magnifier': 'ea1f',
  'c-check': 'ea20',
  'check-e': 'ea21',
  'exclamation-mark': 'ea22',
  'c-warning-e': 'ea23',
  't-warning-e': 'ea24',
  'warning-sign': 'ea25',
  'print': 'ea26',
  'printer': 'ea27',
  'opposite-directions': 'ea28',
  'swap-horizontal': 'ea29',
  'swap-vertical': 'ea2a',
  'pin-e': 'ea2b',
  'pin-3': 'ea2c',
  'circle-10': 'ea2d',
  'circle-10-1': 'ea2e',
  'circle-10-2': 'ea2f',
  'profile': 'ea30',
  'phone-button': 'ea31',
  'phone-button-1': 'ea32',
  'dots-anim-2': 'ea33',
  'shape-oval-2': 'ea34',
  'shape-oval-1': 'ea35',
  'globe': 'ea36',
  'globe-1': 'ea37',
  'ungroup-2': 'ea38',
  'ungroup-1': 'ea39',
  'ungroup-4': 'ea3a',
  'ungroup-3': 'ea3b',
  'settings-gear': 'ea3c',
  'wrench': 'ea3d',
  'cogwheel': 'ea3e',
  'gear': 'ea3f',
  'badge': 'ea40',
  'badge-15': 'ea41',
  'badge-15-1': 'ea42',
  'button-power': 'ea43',
  'button-power-1': 'ea44',
  'setup-preferences': 'ea45',
  'setup-preferences-1': 'ea46',
  'setup-preferences-2': 'ea47',
  'wrench-1': 'ea48',
  'comment': 'ea49',
  'f-comment': 'ea4a',
  'comment-1': 'ea4b',
  'phone': 'ea4c',
  'phone-2': 'ea4d',
  'phone-1': 'ea4e',
  'phone-button-2': 'ea4f',
  'f-check': 'ea50',
  'check': 'ea51',
  'check-1': 'ea52',
  'f-check-1': 'ea53',
  'pin-3-1': 'ea54',
  'calendar-2': 'ea55',
  'calendar-event-create': 'ea56',
  'calendar-2-1': 'ea57',
  'event-create': 'ea58',
  'event-create-1': 'ea59',
  'car-front': 'ea5a',
  'car-front-1': 'ea5b',
  'car-front-2': 'ea5c',
  'e-remove': 'ea5d',
  'e-remove-1': 'ea5e',
  'e-remove-2': 'ea5f',
  'e-remove-3': 'ea60',
  'e-add': 'ea61',
  'e-add-1': 'ea62',
  'time-clock': 'ea63',
  'clock-1': 'ea64',
  'e-delete': 'ea65',
  'e-delete-1': 'ea66',
  'handshake': 'ea67',
  'logo-facebook': 'ea68',
  'logo-facebook-1': 'ea69',
  'instant-camera-2': 'ea6a',
  'logo-twitter': 'ea6b',
  'logo-linkedin': 'ea6c',
  'logo-instagram': 'ea6d',
  'bedroom': 'ea6e',
  'bedroom-1': 'ea6f',
  'google': 'ea70',
  'logo-google-plus': 'ea71',
  'credit-card': 'ea72',
  'credit-card-1': 'ea73',
  'credit-card-2': 'ea74',
  'hat-3': 'ea75',
  'hat-3-1': 'ea76',
  'temple-2': 'ea77',
  'temple-1': 'ea78',
  'temple': 'ea79',
  'money-11-2': 'ea7a',
  'money-11-1': 'ea7b',
  'money-coins': 'ea7c',
  'money-11': 'ea7d',
  'logo-paypal': 'ea7e',
  'logo-paypal-1': 'ea7f',
  'email': 'ea80',
  'mail': 'ea81',
  'mail-1': 'ea82',
  'letter': 'ea83',
  'c-warning-1': 'ea84',
  'c-warning-2': 'ea85',
  'logo-youtube': 'ea86',
  'logo-youtube-1': 'ea87',
  'lock': 'ea88',
  'lock-1': 'ea89',
  'book': 'ea8a',
  'home': 'ea8b',
  'home-1': 'ea8c',
  'plane': 'ea8d',
  'plane-2': 'ea8e',
  'plane-1': 'ea8f',
  'multiple-19': 'ea90',
  'multiple': 'ea91',
  'multiple-19-1': 'ea92',
  'triangle-sm-left': 'ea93',
  'triangle-sm-right': 'ea94',
  'triangle-sm-up': 'ea95',
  'triangle-sm-down': 'ea96',
  'triangle-down': 'ea97',
  'triangle-left': 'ea98',
  'triangle-right': 'ea99',
  'triangle-up': 'ea9a',
  'triangle-left-1': 'ea9b',
  'triangle-right-1': 'ea9c',
  'triangle-up-1': 'ea9d',
  'triangle-down-1': 'ea9e',
  'shape-rectangle-2': 'ea9f',
  'shape-rectangle-1': 'eaa0',
  'shape-rectangle-4': 'eaa1',
  'shape-rectangle-3': 'eaa2',
  'shape-star-2': 'eaa3',
  'shape-star-1': 'eaa4',
  'shape-star-4': 'eaa5',
  'shape-star-3': 'eaa6',
  'view': 'eaa7',
  'eye': 'eaa8',
  'b-eye': 'eaa9',
  'view-2': 'eaaa',
  'view-1': 'eaab',
  'hide': 'eaac',
  'passport-2': 'eaad',
  'passport-1': 'eaae',
  'badge-14': 'eaaf',
  'c-remove': 'eab0',
  'c-delete': 'eab1',
  'e-remove-4': 'eab2',
  'f-remove': 'eab3',
  'f-delete': 'eab4',
  'e-delete-2': 'eab5',
  'map': 'eab6',
  'map-1': 'eab7',
  'metrics': 'eab8',
  'metrics-1': 'eab9',
  'arrow-right': 'eaba',
  'arrow-left': 'eabb',
  'arrow-down': 'eabc',
  'arrow-up': 'eabd',
  'arrow-left-1': 'eabe',
  'arrow-right-1': 'eabf',
  'arrow-down-1': 'eac0',
  'arrow-up-1': 'eac1',
  'circle-arrow-right': 'eac2',
  'circle-arrow-left': 'eac3',
  'circle-arrow-down': 'eac4',
  'circle-arrow-up': 'eac5',
  'hearts-suit': 'eac6',
  'hearts-suit-1': 'eac7',
  'shopping-tag': 'eac8',
  'tag': 'eac9',
  'logout': 'eaca',
  'chequered-flag': 'eacb',
  'chequered-flag-1': 'eacc',
  'compass-04': 'eacd',
  'compass-05': 'eace',
  'circle-10-3': 'eacf',
  'layout-11': 'ead0',
  'profile-1': 'ead1',
  'event-ticket': 'ead2',
  'event-ticket-1': 'ead3',
  'pencil': 'ead4',
  'pen': 'ead5',
  'anchor': 'ead6',
  'anchor-1': 'ead7',
  'car-sport': 'ead8',
  'barcode-qr': 'ead9',
  'barcode-qr-1': 'eada',
  'payment-method': 'eadb',
  'menu-5': 'eadbc',
  'wallet-44': 'eadbd',
  'percent-sign': 'eadbe',
  'cheque-3': 'eadbf',
  'gift': 'eadc0',
  'security': 'eadc1',
  'pasta': 'eadc2',
  'c-check-1': 'eadc3',
  'flag-e-07': 'eadc5',
  'trophy': 'eadc6',
  'sad-e': 'eadc7',
  'vehicles-o': 'eadc9',
  'vehicles': 'eadca',
  'train': 'eadcb',
  'sneeze': 'eadcc',
  'directions_boat': 'eadcd',
  'paw-1': 'eadce',
  'menu-5-1': 'eadd',
  'route': 'eadd0',
  'users-wm': 'eadd1',
  'account': 'eadd3',
  'car-front-3': 'eadd4',
  'ticket': 'eadd5',
  'web-hyperlink': 'eadd6',
  'coins': 'eadd7',
  'world-2': 'eadd8',
  'world': 'eadd9',
  'airport-trolley': 'eadda',
  'delivery-fast': 'eaddb',
  'p-check': 'eaddc',
  'delivery': 'eaddd',
  'car-simple': 'eadde',
  'hand-holding-coin': 'eaddf',
  'broken-heart-2': 'eade',
  'car_17173315-1': 'eade0',
  'jeep_6975665-1': 'eade1',
  'tags-stack': 'eade2',
  'airline-seat': 'eade3',
  'helicopter': 'eade4',
  'helicopter-1': 'eade5',
  'broken-heart-1': 'eadf',
  'toilet': 'eae0',
  'bath-thub': 'eae1',
  'paw': 'eae2',
  'tv': 'eae3',
  'music': 'eae4',
  'double-bed': 'eae5',
  'wheelchair': 'eae6',
  'fridge': 'eae7',
  'armchair': 'eae8',
  'fan': 'eae9',
  'dog': 'eaea',
  'woman-21': 'eaeb',
  'man-20': 'eaec',
  'chess-queen': 'eaed',
  'bath-faucet': 'eaee',
  'hair-dryer': 'eaef',
  'ventilation': 'eaf0',
  'soft-drink': 'eaf1',
  'phone-3': 'eaf2',
  'wifi': 'eaf3',
  'runny-nose': 'eaf4',
  'jacuzzi': 'eaf5',
  'hair-towel': 'eaf6',
  'croissant': 'eaf7',
  'wardrobe': 'eaf8',
  'night-table': 'eaf9',
  'crown': 'eafb',
  'smoking_rooms': 'eafc',
  'desk': 'eafd',
  'curtains': 'eaff',
  'tv-1': 'eb00',
  'premium-economy': 'eb02',
  'bunkbed-1': 'eb03',
  'Frame-7': 'eb05',
  'money-11-2-2': 'eb06',
  'event-confirm': 'eb07',
  'opposite-directions-1': 'eb08',
  'present': 'eb09',
  'export': 'eb0a',
  'dog-1': 'eb0b',
  'trailer': 'eb0c',
  'shuttle': 'eb0d',
  'roof-box': 'eb0e',
  'quad': 'eb0f',
  'moto': 'eb10',
  'caravan': 'eb11',
  'bike-bmx': 'eb12',
  'bus': 'eb13',
  'van': 'eb14',
  'invert-process': 'eb15',
  'e-ticket3': 'eb16',
);

@function unicode($str) {
  @return unquote("\"\\#{$str}\"");
}

@each $name, $code in $icons {
  .icon-#{"" + $name}::before {
    content: unicode($code);
  }
}
//
// Text
//


// Alignment

.text-justify  { text-align: justify !important; }
.text-nowrap   { white-space: nowrap !important; }


// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { 
      @if $text-direction == ltr {
        text-align: left !important;
      } @else {
        text-align: right !important;
      }
    }
    .text#{$infix}-right  { 
      @if $text-direction == ltr {
        text-align: right !important;
      } @else {
        text-align: left !important;
      }
    }
    .text#{$infix}-center { text-align: center !important; }
    .text#{$infix}-truncate { @include text-truncate; }
  }
}


// Weight and italics

.font-light    { font-weight: $font-weight-light !important; }
.font-normal   { font-weight: $font-weight-normal !important; }
.font-semibold { font-weight: $font-weight-semibold !important; }
.font-bold     { font-weight: $font-weight-bold !important; }
.font-italic   { font-style: italic !important; }


// Contextual colors

@each $color, $value in $theme-colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}

.text-white{
  color: $white;

  @include hover-focus{
    color: $white;
  }
}

// Text sizing

.text-mini{
  font-size: 12px;
}

.text-small{
  font-size: $font-size-sm;
  line-height: $line-height-computed;
}

.text-normal{
  font-size: $font-size-base;
  line-height: $line-height-base;
}

.text-large{
  font-size: $font-size-lg;
  line-height: $line-height-computed;
}


// Line height

.leading-none   { line-height: 1; }
.leading-small  { line-height: $line-height-sm; }
.leading-normal { line-height: $line-height-base; }
.leading-large  { line-height: $line-height-lg; }

// Decoration

.text-decoration-none {
  text-decoration: none !important;
}

@keyframes lazyAnimation {
  0% {
    background-color: #fff;
  }

  50% {
    background-color: $gray-light;
  }

  100% {
    background-color: #fff;
  }
}

.lazy-image-placeholder {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: lazyAnimation 1s infinite;
}

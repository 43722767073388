.close {
  font-size: $close-font-size;
  font-weight: $close-font-weight;
  line-height: $line-height-computed;
  color: $close-color;
  opacity: .5;
  @if $text-direction == ltr {
    float: right;
  } @else {
    float: left;
  }

  @include hover-focus {
    color: $close-color;
    text-decoration: none;
    opacity: .75;
  }
}

// stylelint-disable property-no-vendor-prefix, selector-no-qualifying-type
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}
//
// Utilities for Cursor
//

.cursor-pointer, i.icon {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-help {
  cursor: help;
}

.hover-white-smoke:not(.bg-primary-light){
  &:hover {
    background: $white-smoke !important;
  }
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}
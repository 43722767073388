$type-icon-size:  16px;
$vessel-change-color:  #f0ad4e;
$conjunction-trip-border: #FFDCAE;
$conjunction-trip-bgColor: #FFF7EC;
$conjunction-trip-text-bgColor: #FFEACE;  
$conjunction-trip-text-color: #D2913C;

.result-details-dep,
.result-details-arr,
.result-details-trip-stop,
.result-details-stop-info,
.result-details-stop-toggle{
  @if $text-direction == ltr {
    padding-left: ($type-icon-size + calc($grid-gutter-width / 2));
  } @else {
    padding-right: ($type-icon-size + calc($grid-gutter-width / 2));
  }
}

.result-details-arr{
  &:before{
    content: '';
    display: block;
    width: $type-icon-size;
    height: $type-icon-size;
    border-radius: 50%;
    border: 2px solid transparent;
    position: absolute;
    @if $text-direction == ltr {
      left: 0px;
    } @else {
      right: 0px;
    }
    top: theme-spaces(1);
    z-index: 2;
  }

  &.last:before{
    content: '';
    display: block;
    width: $type-icon-size;
    height: $type-icon-size;
    border: 2px solid $primary;
    border-radius: 50%;
    background-color: $white;

    @if $text-direction == ltr {
      left: 0px;
    } @else {
      right: 0px;
    }
  }
}

.result-details-dep{
  &:before{
    content: '';
    width: $type-icon-size;
    height: $type-icon-size;
    border: 2px solid $primary;
    border-radius: 50%;
    background-color: $white;
    display: block;
    position: absolute;
    @if $text-direction == ltr {
      left: 0px;
    } @else {
      right: 0px;
    }
    top: theme-spaces(1);
  }

  &:after{
    content: '';
    display: block;
    width: 0;
    border-right: 2px dashed $primary;

    position: absolute;
    top: ($type-icon-size + theme-spaces(1));
    @if $text-direction == ltr {
      left: calc($type-icon-size / 2) - 1;
    } @else {
      right: calc($type-icon-size / 2) - 1;
    }
    bottom: - theme-spaces(1);
    z-index: 2;
  }

  &.vessel-change {
    &:before{
      background-color: $vessel-change-color;
      border-color: $vessel-change-color;
    }
  }
}

.result-details-count {
  height: 50px;
  top: 1px;
  right: 35px;
  z-index: 10;
  position: relative;

  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 0;
    border-right: 2px dashed #008BFF;
    position: absolute;
    top: 24px;
    left: 42px;
    z-index: 2;
    bottom: 0;
  }
}

.conjunction-trip-details {
  border-radius: 8px;
  border: 1px solid $conjunction-trip-border;
  background: $conjunction-trip-bgColor;
  
  .conjunction-text {
    background-color: $conjunction-trip-text-bgColor;
    color: $conjunction-trip-text-color;
  }
}

.result-details-trip-stop{
  &:before{
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid transparent;
    position: absolute;
    @if $text-direction == ltr {
      left: calc($type-icon-size / 8) - 1;
    } @else {
      right: calc($type-icon-size / 8) - 1;
    }
    top: theme-spaces(1);
    z-index: 2;
  }

  &:after{
    content: '';
    display: block;
    width: 2px;
    position: absolute;
    top: 18px;
    @if $text-direction == ltr {
      left: calc($type-icon-size / 2) - 1;
    } @else {
      right: calc($type-icon-size / 2) - 1;
    }
    bottom: - theme-spaces(2) + 2;
    z-index: 2;
  }

  &.last:after{
    content: normal;
  }
}

.result-details-stop-info:before{
  content: '';
  display: block;
  border: 1px dashed $border-color;
  position: absolute;
  top: - ($type-icon-size + theme-spaces(2));
  bottom: - theme-spaces(1);
  @if $text-direction == ltr {
    left: $type-icon-size - 3;
  } @else {
    right: $type-icon-size - 3;
  }
  z-index: 1;
}

.result-details-stop-toggle{
  position: relative;
  padding-bottom: theme-spaces(2);

  &:before{
    content: "";
    display: block;
    border: 1px dashed $border-color;
    position: absolute;
    top: theme-spaces(2);
    bottom: - theme-spaces(1);
    @if $text-direction == ltr {
      left: calc($type-icon-size / 2) - 1;
    } @else {
      right: calc($type-icon-size / 2) - 1;
    }
    z-index: 2;
  }

  &:after{
    font-family: Nucleo;
    content: "\ea62";
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $type-icon-size;
    height: $type-icon-size;
    border-radius: 50%;
    position: absolute;
    @if $text-direction == ltr {
      left: 0px;
    } @else {
      right: 0px;
    }
    top: 4px;
    z-index: 2;
    background-color: #fff;
    border: 1px solid transparent;
  }
}

.toggleSegmentDetails {
    position: absolute;
    @if $text-direction == ltr {
      right: calc($grid-gutter-width / 2);
    } @else {
      left: calc($grid-gutter-width / 2);
    }
    bottom: -32px;
    z-index: 1;
}

@each $type, $color in $type-colors{
  .result-details-#{$type}{
    .result-details-arr:not(.last){
      &:before{
        background-color: $color;
        border-color: $color;
      }
    }

  /*.result-details-dep{
      &:before{
        content: url('#{$img-path}/#{$brand}/ic_#{$type}.svg');
      }
    
      &:after{
        background-color: transparent;
      }
    }
  */

    .result-details-trip-stop{
      &:before{
        border-color: $primary;
      }

      &:after{
        border-right: 2px dashed $primary;
      }

      &.vessel-change {
        &:before{
          background-color: $vessel-change-color;
          border-color: $vessel-change-color;
        }
      }
    }
  }

  .type-title-#{$type}{
    &:before{
      display: inline-block;
      content: url('#{$img-path}/#{$brand}/ic_#{$type}.svg');
      width: 2em;
      height: 2em;
      vertical-align: middle;
      @if $text-direction == ltr {
        margin-right: theme-spaces(1);
      } @else {
        margin-left: theme-spaces(1);
      }
    }
  }
  .result-details-stop-toggle-#{$type}{
    &:after{
      border-color: $color;
      color: $color;
    }
  }
}

/* new result details ferries */

.dr-result-details-dep,
.dr-result-details-arr,
.dr-result-details-trip-stop {
  @if $text-direction == ltr {
    padding-left: ($type-icon-size + calc($type-icon-size / 2));
  } @else {
    padding-right: ($type-icon-size + calc($type-icon-size / 2));
  }
}

.dr-result-details-arr {
  &:before{
    content: '';
    display: block;
    width: $type-icon-size;
    height: $type-icon-size;
    border-radius: 50%;
    border: 2px solid transparent;
    position: absolute;
    @if $text-direction == ltr {
      left: 0px;
    } @else {
      right: 0px;
    }
    top: theme-spaces(4) - 2;
    z-index: 2;
  }

  &.last:before {
    content: '';
    display: block;
    width: $type-icon-size;
    height: $type-icon-size;
    border: 2px solid $primary;
    border-radius: 50%;
    background-color: rgba($primary, .3);

    @if $text-direction == ltr {
      left: 0px;
    } @else {
      right: 0px;
    }
  }
}

.dr-result-details-dep {
  &:before {
    content: '';
    width: $type-icon-size;
    height: $type-icon-size;
    border: 2px solid $primary;
    border-radius: 50%;
    background-color: rgba($primary, .3);
    display: block;
    position: absolute;
    @if $text-direction == ltr {
      left: 0px;
    } @else {
      right: 0px;
    }
    top: theme-spaces(4) - 2;
  }

  &:after {
    content: '';
    display: block;
    width: 0;
    border-right: 2px dashed $primary;

    position: absolute;
    top: ($type-icon-size + theme-spaces(4) - 2);
    @if $text-direction == ltr {
      left: calc($type-icon-size / 2) - 1;
    } @else {
      right: calc($type-icon-size / 2) - 1;
    }
    bottom: - theme-spaces(4);
    z-index: 2;
  }
}

.dr-result-details-trip-stop {
  &:before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border: 2px solid $primary;
    border-radius: 50%;
    position: absolute;
    @if $text-direction == ltr {
      left: calc($type-icon-size / 8);
    } @else {
      right: calc($type-icon-size / 8);
    }
    top: theme-spaces(4) + 2;
    z-index: 2;
  }

  &:after {
    content: '';
    display: block;
    width: 2px;
    border-right: 2px dashed $primary;
    position: absolute;
    top: theme-spaces(5) + 4;
    @if $text-direction == ltr {
      left: calc($type-icon-size / 2) - 1;
    } @else {
      right: calc($type-icon-size / 2) - 1;
    }
    bottom: - theme-spaces(4);
    z-index: 2;
  }

  &.last:after {
    content: normal;
  }
}

.page-affiliates {
  background-color: $white;

  .modal-dialog {
    margin: 10px auto;

    @include media-breakpoint-up(xs) {
      max-width: 665px;
    }
  }
}

.affiliate-preview {
  text-align: center;
  border: 2px dashed #ddd;
  overflow: auto; 
}

#ferrySearchMaskAffiliates {
  .suggestion-list {
    max-height: 165px;
  }

  .list-group-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .icon-e-add-2, .icon-e-delete-2 {
    margin-bottom: 1px;
  }

  .icon-single-01, .icon-car-front {
    color: #999999;
  }

  .occupancy-options {
    width: 50%;
  }

  .bottom-aff {
    width: 100%;
  }

  .swapAirports {
    @include media-breakpoint-down(xs) {
      width: 32px;
      height: 32px;
      top: 7px;
      right: -24px;
      transform: none;
    }
  }

  .searchMask-tab-wrapper {

    .nav-tabs {
      border: 0px;
    }

    &.outline {
      .searchMask-wrapper {
        border: 1px solid $outline;
      }

      .nav-item {
        margin-bottom: -1px;
        border-bottom: 1px solid $outline;

        &.active {
          border: 1px solid $outline;
          border-bottom: 1px solid $white;
        }
      }
    }

    .searchMask-wrapper {
      .remove-trip {
        display: block;

        button {
          height: auto;
        }
      }
    }

  }

  .add-trip {
    @include media-breakpoint-down(sm){
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}

// Text contextual colors

@mixin text-emphasis-variant($parent, $color) {
  #{$parent} {
    color: $color !important;
  }
  a#{$parent} {
    @include hover-focus {
      color: darken($color, 10%) !important;
    }
  }
}


// Text truncate
// Requires inline-block or block for proper styling

@mixin text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin reset-text {
  font-family: $font-family-base;
  // We deliberately do NOT reset font-size or word-wrap.
  font-style: normal;
  font-weight: $font-weight-normal;
  line-height: $line-height-base;
  text-align: left; // Fallback for where `start` is not supported
  text-align: start; // stylelint-disable-line declaration-block-no-duplicate-properties
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
}


//
// Screenreaders
//

@mixin text-hide() {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.first-letter-uppercase:first-letter {
  text-transform: uppercase;
}

$lines: 2, 3, 4;

@each $line in $lines {
  .max-lines-#{$line} {
    display: -webkit-box;
    -webkit-line-clamp: #{$line};
    -webkit-box-orient: vertical;
    overflow: hidden
  }
}

// Make the div behave like a button
.btn-group{
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  > .btn {
    position: relative;
    flex: 0 1 auto;

    @include hover {
      z-index: 1;
    }

    &:focus,
    &:active,
    &.active {
      z-index: 1;
    }
  }

  // Prevent double borders when buttons are next to each other
  .btn + .btn,
  .btn + .btn-group,
  .btn-group + .btn,
  .btn-group + .btn-group {
    @if $text-direction == ltr {
      margin-left: -1px;
    } @else {
      margin-right: -1px;
    }
  }
}


.btn-group {
  > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0;
  }

  // Set corners individual because sometimes a single button can be in a .btn-group
  // and we need :first-child and :last-child to both match
  > .btn:first-child {
    @if $text-direction == ltr {
      margin-left: 0;
    } @else {
      margin-right: 0;
    }

    &:not(:last-child):not(.dropdown-toggle) {
      @if $text-direction == ltr {
        @include border-right-radius(0);
      } @else {
        @include border-left-radius(0);
      }
    }
  }


  // Need .dropdown-toggle since :last-child doesn't apply given a .dropdown-menu
  // immediately after it
  > .btn:last-child:not(:first-child),
  > .dropdown-toggle:not(:first-child) {
    @if $text-direction == ltr {
      @include border-left-radius(0);
    } @else {
      @include border-right-radius(0);
    }
  }

  // Custom edits for including btn-groups within btn-groups (useful for including
  // dropdown buttons within a btn-group)
  > .btn-group {
    @if $text-direction == ltr {
      float: left;
    } @else {
      float: right;
    }
  }

  > .btn-group:not(:first-child):not(:last-child) > .btn {
    border-radius: 0;
  }

  > .btn-group:first-child:not(:last-child) {
    > .btn:last-child,
    > .dropdown-toggle {
      @if $text-direction == ltr {
        @include border-right-radius(0);
      } @else {
        @include border-left-radius(0);
      }
    }
  }

  > .btn-group:last-child:not(:first-child) > .btn:first-child {
    @if $text-direction == ltr {
      @include border-left-radius(0);
    } @else {
      @include border-right-radius(0);
    }
  }
}


// Sizing
//
// Remix the default button sizing classes into new ones for easier manipulation.

.btn-group-sm > .btn { @extend .btn-sm; }
.btn-group-lg > .btn { @extend .btn-lg; }

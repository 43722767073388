.iframe-container {
 position: relative;
 padding-bottom: 56.25%;
 padding-top: 35px;
 height: 0;
 overflow: hidden;

 iframe{
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
 }
}

.iframe-container.authentication {
  padding-bottom: 65%;

  @media screen and (max-width: 479px) {
    min-height: 500px;
  }
}

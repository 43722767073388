#cms {
  .faq-category {
    width: 255px;
  }

  .faq-card {
    box-shadow:
      0 0 6px rgba(0,0,0, 0.15),
      -2px -2px 4px rgba(255,255,255, 0.5),
      inset 2px 2px 4px rgba(255,255,255, 0.5),
      2px 2px 4px rgba(0,0,0, 0.15)
  }

  .bg-linear {
    background-image: linear-gradient($primary 2px, transparent 0px);
    background-repeat: repeat-x;
    background-position: 0 12px;
  }

  #sidebar {
    .line-selected {
      &:before {
        content: '';
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: $primary;
        width: 2px;
        position: absolute;
      }
    }
  }

  .blog-dropdown-language {
    width: 150px;
    .icon {
      width: 15px;
    }
  }

  .destination-hero {
    height: 476px;
    position: relative;

    .text-truncate {
      position: absolute;
      left: 16px;
      top: 0;
      width: calc(100% - 16px);
    }

    .destination-hero-title {
      height: 100%;

      > div {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        > h1 {
          > span {
            display: block;
          }
        }
      }
    }
  }

  .destination-anchor {
    z-index: 500;
    background-color: $white;

    @include media-breakpoint-down(md) {
      margin-top: 40px;
    }

    .destination-anchors {
      overflow-x: auto;
      //overflow-y: hidden;
      white-space: nowrap;
      position: relative;

      > span {
        cursor: pointer;
        display: inline-block;
        color: $primary;

        &.selected {
          font-weight: bold;
          border-bottom: 2px solid $primary;
        }
      }
    }
  }

  .port-agencies {
    border: 1px solid $border-color;
    border-radius: $border-radius;

    @include media-breakpoint-down(md) {
      border: 0;
    }

    > div {
      border-bottom: 1px solid $border-color;

      @include media-breakpoint-down(md) {
        background-color: $snow;
        margin-bottom: 16px;
        border: 0;
      }

      &:nth-child(odd) {
        background-color: $snow;
      }

      &:first-child {
        background-color: transparent;
      }

      &:last-child {
        border-bottom: 0;
      }

      > div {
        @include media-breakpoint-down(md) {
          padding-bottom: 0 !important;
        }

        &:first-child {
          @include media-breakpoint-down(md) {
            font-weight: bold;
          }
        }

        &:last-child {
          word-wrap: break-word;

          @include media-breakpoint-down(md) {
            padding-bottom: 8px !important;
          }
        }
      }
    }
  }

  .port-section-agencies {
    border: 1px solid $border-color;
    border-radius: $border-radius;

    @include media-breakpoint-down(md) {
      border: 0;
    }

    .multi-agency:nth-child(even) {
      background-color: $snow;
    }

    > div {

      .multi-agency {
        > * {
          @include media-breakpoint-down(md) {
            padding-bottom: 0 !important;
          }
        }

        &:last-child {
          @include media-breakpoint-down(md) {
            padding-bottom: 8px !important;
          }
        }
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 16px;
        border: 1px solid $gray-light;
      }

      &:first-child {
        background-color: transparent;
      }

      &:last-child {
        border-bottom: 0;
      }

      > div {

        &:last-child {
          word-wrap: break-word;
        }
      }
    }
  }

  .tab-anchors {
    overflow-x: auto;
    white-space: nowrap;
    position: relative;

    span {
      cursor: pointer;
      display: inline-block;
      color: #008BFF;

      &.selected {
        font-weight: bold;
        border-bottom: 2px solid #008BFF;
      }
    }
  }

  .font-subtitle {
    font-size: 22px;
  }

  .content-responsive {
    img {
      width: 100%;
      height: auto;
    }

    table {
      width: 100%;
    }
  }
}

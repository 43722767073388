.loading-dots,
.loading-dots:before,
.loading-dots:after {
  width: .5rem;
  height: .5rem;
  border-radius: .25rem;
  transition: all 0.25s ease-in-out
}

.loading-dots {
  position: relative;
}

.loading-dots:before,
.loading-dots:after{
  content: '';
  position: absolute;
  top: 0;
}

.loading-dots:before{
  left: -13px;
  animation-delay: 0s !important;
}

.loading-dots:after{
  left: 13px;
  animation-delay: 0.5s !important;
}

.loading-dots-lg,
.loading-dots-lg:before,
.loading-dots-lg:after {
  width: 1rem;
  height: 1rem;
  border-radius: .5rem;
}

.loading-dots-lg:before {
  left: -26px;
}

.loading-dots-lg:after {
  left: 26px;
}

.loading-dots-sm,
.loading-dots-sm:before,
.loading-dots-sm:after {
  width: .25rem;
  height: .25rem;
  border-radius: .125rem;
}

.loading-dots-sm:before{
  left: -8px;
}

.loading-dots-sm:after{
  left: 8px;
}

.loading-dots,
.loading-dots:before,
.loading-dots:after{
  animation: loadingDotPulse 2s infinite;
}

@keyframes loadingDotPulse {
  0% {
    background: rgba(type-color('flight'),0.15);
  }

  70% {
    background: type-color('flight');
  }

  100% {
    background: rgba(type-color('flight'),0.15);
  }
}

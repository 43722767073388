// Responsive image
//
// Keep images from scaling beyond the width of their parents.

@mixin img-fluid {
  max-width: 100%;
  height: auto;
}


// Aspect Ratio
// 

@mixin aspect-ratio($width, $height) {
  content: '';
  display: block;
  width: 100%;
  padding-top: calc($height / $width) * 100%;
}
.segments-info {
  align-items: flex-start;
  justify-content: center;

  .route-title {
    border-radius: $border-radius-lg;
    background-color: rgba($ferries, 0.06);
  }

  .selected-info-segment {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .view-all-results {
    @include media-breakpoint-down(sm) {
      border-radius: $border-radius-lg;
    }
  }
}

.date-slider {
  .slick-slider {
    padding: 8px 8px 0;

    .slick-list {
      .slick-slide {
        padding-right: theme-spaces(3);
        padding-left: theme-spaces(3);

        .nav-link {
          padding-bottom: 0;
        }
      }
    }
  }
  .slick-next, .slick-prev {
    @include media-breakpoint-up(md) {
      width: 32px;
      padding: 9px;
      text-align: center;
      background-color: $white;
      border-radius: $border-radius;
      box-shadow: 0px 2px 10px rgba(53, 62, 68, 0.25);
    }
  }

  .slick-next {
    right: -16px;
  }

  .slick-prev {
    left: -16px;
  }
}


.group-legs {
  @include media-breakpoint-down(sm) {
    background-color: $body-bg;

    > .ferry-segment {
      margin-top: theme-spaces(2);
      margin-bottom: theme-spaces(2);
    }

    >:first-child {
      margin-top: 0px;
    }

    >:last-child {
      margin-bottom: 0px;
    }
  }
}

.ferry-segment {
  background-color: $snow;
  display: block;
  font-size: $font-size-base;
  line-height: $line-height-sm;
  border-radius: $border-radius-lg;

  &.bookable-modal {
    border-bottom: 0;

    > div > .leg-details {
      border: 1px solid $card-border-color;
      background-color: lighten($gray-light, 5%);
      border-radius: $border-radius;
      padding: 12px;
    }

    &.non-bookable {
      > div > div:last-child {
        color: $gray-mid !important;
        opacity: 0.8 !important;

        img {
          filter: gray; /* IE6-9 */
          -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
          filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
        }
      }
    }

    .proceed-to {
      @include media-breakpoint-down(sm) {
        text-align: center;
      }

      a {
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }
  }

  &:last-child {
    border-bottom: 0;
  }

  &.selected {
    background: linear-gradient(0deg, rgba($ferries, 0.06) 0%, rgba($ferries, 0.06) 100%), $white;
    border-color: $primary !important;

    @include media-breakpoint-down(sm) {
      border-color: $primary !important;
    }
  }

  &.recommended:not(.selected) {
    background: rgba($accent, .05);
    box-shadow: 0px 16px 32px -16px rgba(143, 204, 196, 0.65);

    @include hover-focus {
      cursor: pointer;
      background: rgba($accent, .1);
    }
  }

  &.fastest {
    border: 2px solid $secondary;
  }

  .sold-out {
    opacity: 0.3;

    & img {
      filter: gray;
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
    }
  }

  &:not(.bookable-modal):not(.selected):not(.disabled):not(.recommended) {
    @include hover-focus {
      cursor: pointer;
      background: $gray-light;
    }
  }

  input:checked ~ .price {
    //color: if($brand == 'travelplanet24', $purple, $accent);
    color: $black;
    font-weight: bold;
  }

  .non-bookable-warning {
    top: -8px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    border-radius: 50%;

    > i {
      font-size: 11px;
      margin-left: 1px;
    }
  }

  .conjunction {

    &.top {
      border-top: 1px solid $gray-mid;


      &:before {
        top: 0px;
        bottom: -30px;
      }
    }

    &.bottom {
      border-bottom: 1px solid $gray-mid;


      &:before {
        top: -30px;
        bottom: 0px;
      }
    }

    &.start {
      padding-left: 0;
     
    }

    &.end {
      padding-right: 1px;
    }

    &.middle {
      &:before {
        top: -30px;
        bottom: -30px;
      }
    }

    &:before {
      content: '';
      display: block;
      width: 0;
      border-left: 1px solid $gray-mid;
      position: absolute;
    }
  }
}

.select-list-group:hover {
  background: lighten($ferries, 40%);
  cursor: pointer;
  transition: 0.3s;
}

.bg-select-item {
  background-color: lighten($ferries, 40%);
}

#vessel-info {
  .popover-content {
    padding: 0;
  }
}

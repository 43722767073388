// 
// Responsive images (ensure images don't scale beyond their parents)
//

.img-fluid {
  @include img-fluid;
}


// 
// Image thumbnails
// 

.img-thumbnail {
  padding: theme-spaces(1);
  background-color: $white;
  border: 1px solid $border-color;
  border-radius: $border-radius-sm;

  // Keep them at most 100% wide
  @include img-fluid;
}


// 
// Aspect Ratio
// 

.img-ratio-16-9 {
  position: relative;
  &:before{
    @include aspect-ratio(16,9);
  }
}

.img-ratio-4-3 {
  position: relative;
  &:before {
    @include aspect-ratio(4,3);
  }
}

.img-ratio-3-2 {
  position: relative;
  &:before {
    @include aspect-ratio(3,2);
  }
}

.img-ratio-21-9 {
  position: relative;
  &:before {
    @include aspect-ratio(21,9);
  }
}

.img-cover {
  object-fit: cover;
  object-position: center center;
}

// Object fit

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

// Wordpress images

figure.wp-caption {
  width: 100% !important;
}

// Navbar
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

$logo-margin-sections: 46px;
$section-padding-x: 12px;

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: $navbar-bg;
  padding-top: $navbar-padding-y;
  padding-bottom: $navbar-padding-y;
  min-height: 64px;

  > .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
  display: inline-block;
  padding-top: $navbar-brand-padding-y;
  padding-bottom: $navbar-brand-padding-y;
  font-size: $navbar-brand-font-size;
  line-height: inherit;
  white-space: nowrap;
  color: $navbar-link-active-color;

  @if $text-direction == ltr {
    margin-right: $logo-margin-sections;
  } @else {
    margin-left: $logo-margin-sections;
  }

  @include hover-focus {
    text-decoration: none;
    color: $navbar-link-active-color;
  }

  > img {
    width: 160px;
    height: auto;
  }

  &.more-space {
    padding: 0;
    margin-right: 10px;

    > img {
      width: 186px;
    }
  }
}

// Navbar nav
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;

  @include media-breakpoint-up(lg) {
    align-items: center;
  }

  .navbar-link {
    display: block;
    padding-right: 0;
    padding-left: 0;
  }
}

// Only home page desktop
@include media-breakpoint-up(lg) {
  .page-ferry_home {
    .navbar-nav:not(.airtickets) {
      &.navbar-nav-main {
        .nav-item {
          position: relative;

          &:first-child {
            &::after {
              display: block;
              content: '';
              width: calc(100% - 2 * #{$section-padding-x});
              height: 2px;
              background-color: $white;
              position: absolute;
              left: $section-padding-x;
              top: calc(100% + 10px);
            }

            .navbar-link {
              color: $white;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

// Navbar text
//
//

.navbar-text {
  display: inline-block;
  padding-top: $navbar-link-padding-y;
  padding-bottom: $navbar-link-padding-y;
  color: $navbar-link-color;

  a{
    color: $navbar-link-active-color;

    @include hover-focus {
      color: $navbar-link-active-color;
    }
  }
}

// Navbar text
//
//

.navbar-link {
  display: inline-block;
  padding: $navbar-link-padding-y $navbar-link-padding-x;
  color: $navbar-link-color;
  
  @include hover {
    text-decoration: none;
    color: $navbar-link-hover-color;
  }

  &.disabled {
    color: $navbar-link-disabled-color;
  }

  .show > .navbar-link,
  .active > .navbar-link,
  .navbar-link.show,
  .navbar-link.active {
    color: $navbar-link-active-color;
  }
}

// Responsive navbar
//
// Custom styles for responsive collapsing and toggling of navbar contents.

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

// Navbar toggle

.navbar-toggle{
  padding: 0;
  border: 0;
  background-color: transparent;

  @include hover-focus {
    text-decoration: none;
    outline: none;
  }

  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    margin: 4px 0;
    transition: 0.2s;
    background-color: $navbar-link-color;
  }

  &:not(.collapsed){
    .icon-bar:nth-child(2) {
      transform: rotate(-45deg) translate(-5px, 5px);
    }
    .icon-bar:nth-child(3) {
      opacity: 0;
    }
    .icon-bar:nth-child(4) {
      transform: rotate(45deg) translate(-3px, -4px);
    }
  }
}


// Configuring
// where your navbar collapses.

.navbar {
  @include media-breakpoint-up(lg) {
    flex-flow: row nowrap;
    justify-content: flex-start;

    .navbar-nav {
      flex-direction: row;


      .navbar-link{
        padding-right: $section-padding-x;
        padding-left: $section-padding-x;
      }
    }

    .dropdown-menu {
      position: absolute;
    }

    .dropdown-menu-right {
      @if $text-direction == ltr {
        right: 0;
        left: auto; // Reset the default from `.dropdown-menu`
      } @else {
        left: 0;
        right: auto;
      }
    }

    // For nesting containers, have to redeclare for alignment purposes

    > .container{
      flex-wrap: nowrap;
    }

    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }

    .navbar-toggle {
      display: none;
    }
  }
}

// Navbar Dropdown
// 

.navbar .navbar-nav{
  @include media-breakpoint-down(md) {
    &:not(.navbar-nav-main) {
      border-top: 1px solid rgba($white, .3);
      margin-top: 10px;
      padding-top: 10px;

      &.airtickets {
        border-top: 1px solid rgba($black, .3);
      }
    }

    .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;
    }

    .dropdown-item,
    .dropdown-item-text{
      color: $navbar-link-color;

      @include hover-focus{
        background-color: transparent;
      }
    }
  }
}

//
// Base styles
//

.alert {
  position: relative;
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  background-color: $white;
  border: 1px solid $alert-border-color;
  border-radius: $alert-border-radius;
  @if $text-direction == ltr {
    padding-left: ($alert-icon-size + $alert-padding-y * 2);
    border-left-width: 4px;
  } @else {
    padding-right: ($alert-icon-size + $alert-padding-y * 2);
    border-right-width: 4px;
  }

  &:before{
    font-family: Nucleo;
    font-size: $alert-icon-size;
    line-height: $line-height-computed;
    padding: $alert-padding-y;
    position: absolute;
    top: 0;
    @if $text-direction == ltr {
      left: 0;
    } @else {
      right: 0;
    }
  }
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $font-weight-semibold;
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: ($close-font-size + $alert-padding-y * 2);

  // Adjust close link position
  .close {
    padding: $alert-padding-y;
    color: inherit;
    position: absolute;
    top: 0;
    @if $text-direction == ltr {
      right: 0;
    } @else {
      left: 0;
    }
  }
}


// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $alert-variant {
  .alert-#{$color} {
    @include alert-variant($white, $value, $body-color);

    &:before{
      @if $color == success {
        content: "\ea20";
      } @else if $color == warning {
        content: "\ea24";
      } @else if $color == danger {
        content: "\ea23";
      } @else {
        content: "\ea06";
      }
    }
  }
}

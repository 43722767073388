// The dropdown wrapper (`<div>`)

.dropdown {
  position: relative;
}


// The dropdown menu

.dropdown-menu {
  display: none; // none by default, but block on "open" of the menu
  position: absolute;
  top: 100%;
  @if $text-direction == ltr {
    left: 0;
    float: left;
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  } @else {
    right: 0;
    float: right;
    text-align: right;
  }
  z-index: $zindex-dropdown;
  min-width: $dropdown-min-width;
  padding: $dropdown-padding-y 0;
  margin: $dropdown-spacer 0 0; // override default ul
  font-size: $font-size-base - 1; // Redeclare because nesting can cause inheritance issues
  color: $body-color;
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;
  border-radius: $dropdown-border-radius;
  box-shadow: $dropdown-box-shadow;

  a {
    text-decoration: none;
  }
}

.open .dropdown-menu {
  display: block !important;
}

// Links, buttons, and more within the dropdown menu
//

.dropdown-menu-item > a,
.dropdown-item {
  display: block;
  width: 100%;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  clear: both;
  font-weight: normal;
  color: $dropdown-link-color;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  @include hover-focus {
    color: $dropdown-link-hover-color;
    text-decoration: none;
    background-color: $dropdown-link-hover-bg;
  }

  &.active,
  &:active {
    color: $dropdown-link-active-color;
    text-decoration: none;
    background-color: $dropdown-link-active-bg;
  }

  &.disabled,
  &:disabled {
    color: $dropdown-link-disabled-color;
    background-color: transparent;
  }
}

.dropdown-menu-right{
  @if $text-direction == ltr {
    left: auto;
    right: 0;
  } @else {
    right: auto;
    left: 0;
  }
}

// Dividers (basically an `<hr>`) within the dropdown

.dropdown-divider {
  height: 0;
  margin: theme-spaces(1) 0;
  overflow: hidden;
  border-top: 1px solid $border-color;
}

// Dropdown text

.dropdown-item-text {
  display: block;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  color: $dropdown-link-color;
}


// Customizations

.scrollable-menu {
  height: auto;
  min-width: 100%;
  max-height: 352px;
  overflow-x: hidden;
  white-space: nowrap;
}

.open > .btn > .icon-down-arrow-2 {
  transition: .2s;
  transform: rotateX(-180deg);
}


.actions-menu.dropdown {
  &:hover {
    background: $snow;
  }
}

$masthead-bg: $gray-dark !default;

// 
// Base style
//

.masthead{
  padding: theme-spaces(3) 0;
  background-color: $masthead-bg;
  color: color-yiq($masthead-bg);

  .more-options .less {
    display: none;
  }
}


// recent-searches

.recent-searches-tabs{
  background-color: $masthead-bg;
  margin-bottom: theme-spaces(4);

  .nav-tabs{
    
    .nav-link{
      border: 1px solid $masthead-bg;
      color: $nav-link-disabled-color;
      background-color: $body-bg;

      @include hover{
        color: $nav-link-active-color;
      }
    }

    .nav-item.active > .nav-link {
      border-color: $body-bg;
      border: 1px solid $masthead-bg;
      border-bottom: 1px solid $body-bg;
      background-color: $body-bg;
    }
  }
}

#results-ad {
  width: 120px;
  position: absolute;
  right: -120px;
}

#results-ad div {
  margin-bottom: 10px;
}

@media (max-width: 1410px) {
  #results-ad {
    display: none;
  }
}

// Page width
//
// Set the page width.

.container {
  @include make-container();
  @include make-container-max-widths();
}

@each $breakpoint, $container-max-width in $container-max-widths{
  $infix: breakpoint-infix($breakpoint, $container-max-widths);
  @include media-breakpoint-up($breakpoint){
    .container#{$infix}{
      max-width: $container-max-width;
    }
  }
}

.page-ferry_results {
  @media (min-width: 1400px) {
    .container {
      max-width: 1400px;
    }
  } 
}

// Row
//
// Rows contain your columns.

.grid {
  @include make-row();
}

// Gutter modifiers
// To apply a different gutter, add one of the following classes to .row

.no-gutter {
  margin-right: 0;
  margin-left: 0;

  > .grid-item,
  > [class*="grid-item-"]{
    padding-right: 0;
    padding-left: 0;
  }
}

.small-gutter {
  margin-right: calc($grid-gutter-width-small / -2);
  margin-left: calc($grid-gutter-width-small / -2);

  > .grid-item,
  > [class*="grid-item-"]{
    padding-right: calc($grid-gutter-width-small / 2);
    padding-left: calc($grid-gutter-width-small / 2);
  }
}

.tiny-gutter {
  margin-right: calc($grid-gutter-width-tiny / -2);
  margin-left: calc($grid-gutter-width-tiny / -2);

  > .grid-item,
  > [class*="grid-item-"]{
    padding-right: calc($grid-gutter-width-tiny / 2);
    padding-left: calc($grid-gutter-width-tiny / 2);
  }
}


// Columns
//
// Common styles for grid columns

%grid-col {
  @include make-col-ready;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  
  @each $width, $length in $sizes{
    .grid-item#{$infix}-#{$width} {
      @extend %grid-col;
    }
  }

  .grid-item#{$infix},
  .grid-item#{$infix}-auto {
    @extend %grid-col;
  }

  @include media-breakpoint-up($breakpoint) {
    
    .grid-item#{$infix} {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }

    .grid-item#{$infix}-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }

    @each $width, $length in $sizes{
      .grid-item#{$infix}-#{$width} {
        @include make-col($size: nth($length, 1), $columns: nth($length, 2));
      }
    }

    @each $width, $length in $sizes{
      .offset#{$infix}-#{$width} {
        @include make-col-offset($size: nth($length, 1), $columns: nth($length, 2));
      }
    }

    .grid-divider#{$infix} {
      > .grid-item,
      > [class*="grid-item-"]{
        &:nth-child(n+2){
          border-left: 1px solid $border-color;
        }
      }
    }

    .order#{$infix}-first {
      order: -1;
    }

    .order#{$infix}-1 {
      order: 1;
    }

    .flex#{$infix}-1 {
      flex: 1;
    }

    .flex#{$infix}-auto {
      flex: 1 1 auto;
    }
  }
}

//
// Basic Bootstrap table
//

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $spacer;

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: top;
    border-top: $table-border-width solid $table-border-color;
  }

  thead th {
    vertical-align: bottom;
    border-top: 0;
    border-bottom: (2 * $table-border-width) solid $table-border-color;
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }
}


//
// Condensed table w/ half padding
//

.table-small {
  th,
  td {
    padding: $table-cell-padding-sm;
  }
}


// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }

  thead {
    th,
    td {
      border-bottom-width: (2 * $table-border-width);
    }
  }
}


// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  tbody tr:nth-of-type(odd) {
    background-color: $table-accent-bg;
  }
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  tbody tr {
    @include hover {
      background-color: $table-hover-bg;
    }
  }
}


// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.

.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057

        // Prevent double border on horizontal scroll due to use of `display: block;`
        &.table-bordered {
          border: 0;
        }
      }
    }
  }
}

// 
// RWD Table
// 

.rwd-table{
  > thead,
  > tbody,
  > tfoot {
    > tr {
      
      > th{
        display: none;
      }

      > td{
        display: block;
        
        @include media-breakpoint-down(sm){
          @if $text-direction == ltr {
            text-align: right;
          } @else {
            text-align: left;
          }
        }

        &:first-child{
          border-top-width: 0;
        }

        &:before{
          content: attr(data-th)": ";
          font-weight: $font-weight-semibold;
          width: 50%;
          @if $text-direction == ltr {
            float: left;
            text-align: left;
          } @else {
            text-align: right;
            float: right;
          }
          @include text-truncate();

          @include media-breakpoint-up(md) {
            display: none;
          }
        }
      }
    }
  }
}

.rwd-table{
  > thead,
  > tbody,
  > tfoot {
    > tr{
      > th,
      > td {
        @include media-breakpoint-up(md){
          display: table-cell;
        }
      }
      > td:first-child{
        @include media-breakpoint-up(md){
          border-top-width: 1px;
          margin-top: 0;
        }
      }
    }
  }
}
// 
// Social links

.footer-social-link{
  font-size: $font-size-xs;
  line-height: $line-height-computed;
  color: $text-muted;

  @include hover{
    color: $white;
  }
}

.footer-social-link + .footer-social-link{
  @if $text-direction == ltr {
    padding-left: theme-spaces(3);
  } @else {
    padding-right: theme-spaces(3);
  }
}



// footer-link-list
// 

.footer-link-list{
  @include list-unstyled;

  > li {
    font-size: $font-size-sm;

    a {
      color: $text-muted;

      @include hover{
        color: $white;
        text-decoration: none;
      }
    }
  }
}


// footer-title

.footer-title{
  font-weight: bold;
  line-height: $line-height-computed;
}

// Footer copyright

.footer-secure-logos{
  height: 34px;
}

.footer-bank-logos{
  width: 120px;
  
  @include hover{
    cursor: pointer;
  }
}

.footer-copyright-logos{
  opacity: 0.6;
  height: 60px;
}


#site-footer{
  hr{
    margin-top: theme-spaces(3);
    margin-bottom: theme-spaces(3);
  }
}

.footer-logo {
  max-width: 150px;
}

.icon-shape-star-4 {
    position: relative;
  
    &.half-star:before {
      overflow: hidden;
      color: $white;
    }
  
    &.half-star:after {
      content: '\eaa5';
      display: block;
      position: absolute;
      top: 0;
      width: 50%;
      overflow: hidden;
      color: $warning;
    }
  }
// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.checkbox,
.radio{

  label{
    display: block;
    cursor: pointer;
    font-size: $font-size-base;
  }
  
  &.disabled > label{
    cursor: not-allowed;
  }
}

.checkbox,
.radio{
  input[type="radio"],
  input[type="checkbox"] {
    appearance: none;
    position: relative;
    outline: none;
    width: 16px;
    height: 16px;
    background: $white;
    display: inline-block;
    vertical-align: middle;
    flex: none;
    border: 2px solid #ccc;

    @if $text-direction == ltr {
      margin-right: theme-spaces(1);
    } @else {
      margin-left: theme-spaces(1);
    }
    transition: 0.1s all linear;

    &:disabled{
      background: $input-disabled-bg;
      cursor: not-allowed;
    }

    &:checked{
      border-color: $form-check-input-active-bg; 
      background: $form-check-input-active-bg;
    }
  }
}

.radio{
  input[type="radio"]{
    border-radius: 50%;

    &:checked{
      box-shadow: inset 0px 0px 0px 2px $white;
    }
  }
}

.radio-primary{
  input[type="radio"]{
    &:checked{
      border-color: $accent;
      background: $accent;
    }
  }
}

.checkbox{
  input[type="checkbox"]{
    border-radius: $border-radius-sm;
    
    &:after{
      content: '';
      color: #fff;
      font-weight: $font-weight-semibold;
      line-height: 1;
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &:checked{

      &:after{
        content: '\2713';
      }
    }
  }
}

// 
// Custom Checkbox
// 

.custom-checkbox{
  position: relative;
  display: block;

  label{
    display: block;
    cursor: pointer;
    font-size: $font-size-base;
  }
}

.custom-checkbox-label{
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  content: '';
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.custom-checkbox-input{
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:checked ~ .custom-checkbox-label{
    border-color: $form-check-input-active-bg;
    background: $form-check-input-active-bg;

    &:after{
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpolyline points='6 12 10 16 18 8' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3e%3c/svg%3e");
    }
  }

  &:disabled ~ .custom-checkbox-label{
    background: $input-disabled-bg;
    cursor: not-allowed;
  }

  &:checked[disabled] ~ .custom-checkbox-label{
    background: $input-disabled-bg;
    border-color: #ccc;

    &:after{
      border-color: #ccc;
    }
  }
}

.has-error .custom-checkbox-label {
  border-color: theme-color('danger');
}

// 
// Toggle Switch
// 

.toggle-switch {
  position: relative;
  display: inline-block;
  width: ($toggle-switch-slider-size * 2) + ($toggle-switch-gutter * 2);
  height: $toggle-switch-slider-size + ($toggle-switch-gutter * 2);
}

.toggle-switch input {display:none;}

.toggle-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100px;
  background-color: $input-border-color;
  transition: .4s;
  
  &:before {
    position: absolute;
    content: "";
    height: $toggle-switch-slider-size;
    width: $toggle-switch-slider-size;
    @if $text-direction == ltr {
      left: $toggle-switch-gutter;
    } @else {
      right: $toggle-switch-gutter;
    }
    bottom: $toggle-switch-gutter;
    background-color: $white;
    transition: .4s;
    border-radius: 50%;
  }
}


.toggle-switch input:checked {
  + .slider {
    background-color: $form-check-input-active-bg;
  }
}

.toggle-switch input {
  &:disabled {
    + .slider {
      background-color: $input-disabled-bg;
      cursor: not-allowed;
    }
  }
}

.toggle-switch input:focus + .slider {
  box-shadow: 0 0 1px $form-check-input-active-bg;
}

.toggle-switch input:checked + .slider:before {
  @if $text-direction == ltr {
    transform: translateX($toggle-switch-slider-size);
  } @else {
    transform: translateX(-$toggle-switch-slider-size);
  }
}

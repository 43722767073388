//
// Base styles
//

.btn {
  position: relative;
  display: inline-block;
  font-weight: $btn-font-weight;
  text-align: center;
  // white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: $btn-border-radius;

  @include button-size(
    $btn-padding-y, 
    $btn-padding-x, 
    $font-size-base, 
    $btn-line-height
  );
  
  transition: $btn-transition;

  @include hover-focus {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: $btn-disabled-opacity;
  }

  &:active,
  &.active {
    background-image: none;
  }
}

// Loader

.btn{
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.320, 1.275) all;

  &.is-loading{
    @if $text-direction == ltr {
      padding-right: 56px;
    } @else {
      padding-left: 56px;
    }
  }
}

.btn-loader {
  position: absolute;
  top: 50%;
  opacity: 0;
  display: inline-block;
  border: 2px solid transparent;
  border-top-color: transparent;
  border-radius: 50%;
  width: 1.25em;
  height: 1.25em;
  margin-top: -0.625em;
  pointer-events: none;
  animation: spin .6s linear infinite;

  .is-loading &{
    opacity: 1;
    @if $text-direction == ltr {
      right: 1em;
    } @else {
      left: 1em;
    }
  }
}


//
// Alternate buttons
//

@each $color, $value in $button-variant {
  .btn-#{$color} {
    @if $color == "default" {
      @include button-variant($white, #ccc);
    } @else {
      @include button-variant($value, $value);
      color: $white;
    }

    .btn-loader {
      border-color: rgba(color-yiq($value),.2);
      border-top-color: color-yiq($value);
    }
  }
}

@each $color, $value in $button-variant {
  .btn-outline-#{$color} {
    @if $color == "light" {
      @include button-outline-variant($value, $gray-dark);
    } @else {
      @include button-outline-variant($value, $white);
    }
    .btn-loader {
      border-color: rgba($value,.2);
      border-top-color: $value;
    }
  }
}


//
// Button Sizes
//

.btn-sm {
  @include button-size(
    $btn-padding-y-sm, 
    $btn-padding-x-sm, 
    $font-size-base, 
    $btn-line-height-sm
  );
}

.btn-lg {
  @include button-size(
    $btn-padding-y-lg, 
    $btn-padding-x-lg, 
    $font-size-lg, 
    $btn-line-height-lg
  );
}


//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}


// 
// Button icon
// 

.btn-icon {
  position: relative;

   > span,
   > .btn-overlay-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    width: $btn-icon-width;
    color: inherit;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.btn-icon:not(.btn-icon-flip){
  @if $text-direction == ltr {
    padding-left: $btn-icon-width;
  } @else {
    padding-right: $btn-icon-width;
  }
  
  > span,
  > .btn-overlay-icon {
    @if $text-direction == ltr {
      left: 0;
    } @else {
      right: 0;
    }
  }
}

.btn-icon-flip{
  @if $text-direction == ltr {
    padding-right: $btn-icon-width;
  } @else {
    padding-left: $btn-icon-width;
  }

  > span{
    @if $text-direction == ltr {
      right: 0;
    } @else {
      left: 0;
    }
  }
}


// btn-to-top

.btn-to-top{
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  color: $gray-dark;
  opacity: .5;
  z-index: $zindex-modal;

  @include hover{
    color: inherit;
    opacity: 1;
  }
}

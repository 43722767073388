#payment-confirmation {

  .payment-title {
    @media (max-width: 768px) {
      font-size: 30px;
    }
  }

  .payment-desc {
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
}

//
// React-slick
// React carousel component
// https://github.com/akiran/react-slick
//

.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}
.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slider {
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  direction:ltr !important;
}
.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: 0;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translate3d(0, 0, 0);
}
.slick-track {
  top: 0;
  left: 0;
}
.slick-track:after,
.slick-track:before {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;

  @include hover{
    text-decoration: none;
  }
}
[dir=rtl] .slick-slide {
  text-align: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}


// 
// Dots
// 

.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0;
}

.slick-dots {
  bottom: -15px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  // margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  border: 0;
  outline: 0;
  background: 0 0;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}
.slick-dots li button:before {
  content: ' ';
  background-color: #1b1b1b;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  opacity: .25;
  border-radius: 50%;
}
.slick-dots li.slick-active button:before {
  opacity: .75;
  color: inherit;
}


// 
// arrows
// 

.slick-next,
.slick-prev {
  font-family: Nucleo;
  font-size: 0;
  line-height: 0;
  top: 50%;
  // width: 2rem;
  // height: 2rem;
  text-align: center;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  outline: 0;
  background: 0 0;
  z-index: 1;
}
.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  outline: 0;
  background: 0 0;
}
.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1;
}
.slick-next,
.slick-prev{
  &.slick-disabled{
    cursor: not-allowed;
    &:before{
      opacity: 0.2;
    }
  }
}
.slick-next:before,
.slick-prev:before {
  font-size: 1rem;
  line-height: 1;
  color: inherit;
}
.slick-prev {
  left: -12px;
}
[dir=rtl] .slick-prev {
  right: -12px;
  left: auto;
}
.slick-prev:before {
  content: "\ea0a";
}
.slick-next:before,
[dir=rtl] .slick-prev:before {
  content: "\ea0c";
}
.slick-next {
  right: -12px;
}
[dir=rtl] .slick-next {
  right: auto;
  left: -12px;
}
[dir=rtl] .slick-next:before {
  content: "\ea0a";
}


// slider-caption
.slider-caption{
  position: absolute;
  bottom: theme-spaces(3);
  @if $text-direction == ltr {
    right: theme-spaces(3);
  } @else {
    left: theme-spaces(3);
  }
  color: $snow;
}
$seat-w: 38px;
$seat-h: 38px;
$emergency-exit: #ee581e;

$standard-seat: lighten($info, 40%);
$premium-seat: lighten($warning, 30%);
$giorgos-seat: lighten($danger, 20%);
$chosen-seat: $success;
$reserved-seat: #f5f5f5;

// Seat Container
// 
// 

.seat-map-container{
  position: relative;
  display: flex;
  max-height: calc(100vh - 276px);
  overflow: auto;

  &.bottom-sheet-open{
    overflow: hidden;
  }

  > .seat-map-plane{
    padding: 0 theme-spaces(2);
    background-color: #FFF;
    margin: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  }
}

.select-flight-container{
  padding: 15px;

  > .floating-label{
    margin-bottom: 0;
  }
}

// Seat Tag

.seat-tag{
  display: inline-block;
  border: 0;
  padding: 0;
  min-width: 32px;
  font-size: 0.9em;
  line-height: 1.4;
  text-align: center;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #e8e8e8;
  -webkit-appearance: none;

  &.assigned{
    background-color: $success;
    color: #fff;
  }
  
  .seat-tag-label,
  .seat-tag-icon{
    display: inline-block;
    vertical-align: middle;
  }

  .seat-tag-label{
    padding: 2px 5px;
  }

  .seat-tag-icon{
    color: rgba(#000, .2);
    font-weight: bold;
    font-family: Arial, sans-serif;
    padding: 2px 5px;
    border-left: 1px solid rgba(#000, .05);
  }

  &:hover,
  &:focus{
    outline: 0;
    .seat-tag-icon{
      color: rgba(#000, .4)
    }
  }
}

// Seat row 
// 
// 

.seat-map-row{
  position: relative;
  padding: theme-spaces(1) 0 theme-spaces(1);
  margin:0;
  list-style: none;
  white-space: nowrap;

  > li{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: $seat-w;
    height: $seat-h;
    line-height: $seat-h;
    text-align: center;
    margin: 0 theme-spaces(1);
  }

  &:first-child{
    margin-bottom: 0;
  }
}

// Seat legroom

.seat-map-row.exit-row {
  padding-top: 1em;

   > li{

    &:first-child,
    &:last-child {
      &:before{
        font-family: Nucleo;
        font-size: 1.125em;
        position: absolute;
        color: $emergency-exit;
        top: -1.6em;
      }
    }

    &:first-child{
      &:before{
        @if $text-direction == ltr {
          /* content: $icon-small-triangle-left; */
          left: -.65em;
        } @else {
          /* content: $icon-small-triangle-right; */
          right: -.65em;
        }
      }
    }

    &:last-child{
      &:before{
        @if $text-direction == ltr {
          /* content: $icon-small-triangle-right; */
          right: -.65em;
        } @else {
          /* content: $icon-small-triangle-left; */
          left: -.65em;
        }
      }
    }
  }
}

// Wing Seat
.seat-map-plane {

  > .seat-map-row.wing-row{
    &:before,
    &:after{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 4em;
    }

    &:before{
      left: -4.5em;
      background: linear-gradient(to right, #fff, #eee);
    }

    &:after{
      right: -4.5em;
      background: linear-gradient(to left, #fff, #eee);
    }
  }
}


// Seat Map
// 
// 

.seat-map-item{
  border-radius: $border-radius;
  background-color: $standard-seat;
  color: #fff;
  transition: all .1s ease-in-out;

  &:hover,
  &:focus{
    cursor: pointer;
    background-color: darken($standard-seat, 20%);
  }
  
  &.standard-seat{background-color: $standard-seat;}
  &.premium-seat{background-color: $premium-seat;}
  &.giorgos-seat{background-color: $giorgos-seat;}

  &.assigned{
    background-color: $chosen-seat;
    color: #fff;
  }

  &.disabled{
    background-color: $reserved-seat;

    &:hover,
    &:focus{
      cursor: not-allowed;
    }
  }
}

// Seat Overview
// 
// 

.seat-overview-container{
  background-color: #FFF;
  padding: 15px;
  max-height: calc(100vh - 186px);
  overflow-y: auto;

  @include media-breakpoint-down(sm){
    position: fixed;
    top: 61px;
    bottom: 0;
    right: 1px;
    left: 1px;
    z-index: 1060;
    transform: translateY(100vh);
    transition: all 450ms cubic-bezier(0.32,1,0.23,1) 100ms;

    &.in{
      transform: translateY(0);
    }
  }
}

.seat-overview-trigger{
  @if $text-direction == ltr {
    padding-right: theme-spaces(1);
    margin-right: calc(theme-spaces(4) / 2);
    border-right: 1px solid $border-color;
  } @else {
    padding-left: theme-spaces(1);
    margin-left: calc(theme-spaces(4) / 2);
    border-left: 1px solid $border-color;
  }
  .btn{
    padding-left: theme-spaces(2);
    padding-right: theme-spaces(2);
    border: none;
  }

  @include media-breakpoint-up(md){
    display: none;
  }
}

.seat-passenger-list{
  @include list-unstyled;

  > li{
    display: flex;
    align-items: center;
  }

  > li + li{
    margin-top: theme-spaces(1);
  }
}

// Seat Modal
// 
// 

.seat-selection-modal{
  overflow: hidden;

  @include media-breakpoint-up(md){
    max-width: 992px;
  }
  
  .modal-body{
    padding-bottom: 0px;
  }
}

// Seat selection-details Table

.seat-selection-details-table{
  > tbody > tr:nth-of-type(even) > td{
    background-color: $gray-light;
  }

  > tbody > tr > td{
    @if $text-direction == ltr {
      text-align: left;
    } @else {
      text-align: right;
    }

    &:before{
      width: auto;
      @if $text-direction == ltr {
        margin-right: theme-spaces(2);
      } @else {
        margin-left: theme-spaces(2);
      }
    }
  }
}

// Seat Legend
// 
// 



.seat-legend{
  margin: 0;
  padding: 0;
  list-style: none;

  > li + li{
    margin-top: theme-spaces(3);
  }

  .icon{
    font-size: 1.5em;
    min-width: 1em;
    @if $text-direction == ltr {
      margin-right: theme-spaces(2);
    } @else {
      margin-left: theme-spaces(2);
    }
  }
}

.standard-seat{ color: $standard-seat;}
.premium-seat{ color: $premium-seat;}
.giorgos-seat{ color: $giorgos-seat;}
.chosen-seat{ color: $chosen-seat;}
.reserved-seat{ color: darken($reserved-seat, 5%);}
.emergency-exit{ color: $emergency-exit;}


// Bottom Sheet
// 
// 

.bottom-sheet-passengers-list{
  > li:not(:first-child){
    cursor: pointer;

    &:last-child{
      @include border-bottom-radius(6px);
    }
  }
}



// bottom-sheet

.bottom-sheet{
  position: absolute;
  top: 60px;
  right: 0px;
  left: 0px;
  bottom: 0;
  z-index: 1060;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.bottom-sheet-overlay{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(#000, .5);
  @include border-bottom-radius($border-radius-lg);
  @include animate(fade, 0.2s, ease-out);
}

.bottom-sheet-dialog{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  @include animate(slide-bottom, 0.3s, ease-out);
}

.bottom-sheet-content{
  position: relative;
  background-color: $modal-content-bg;
  background-clip: padding-box;
  outline: 0;
  @include border-bottom-radius($border-radius-lg);
}

.bottom-sheet-block{
  padding: theme-spaces(3);

  *:last-child{
    margin-bottom: 0;
  }
}


// Empty

.seatMapEmpty{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6);
  padding: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  span{
    background-color: #FFF;
    padding: 5px;
    font-size: 18px;
  }
}

// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {
  color: color-yiq($background);
  background-color: $background;
  border-color: $border;

  @include hover-focus {
    color: color-yiq($hover-background);
    border-color: $hover-border;
    background-color: $hover-background;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: $background;
    border-color: $border;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active{
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;
  }
}

@mixin button-outline-variant($color, $color-hover: #fff) {
  color: $color;
  background-color: transparent;
  background-image: none;
  border-color: $color;

  @include hover-focus {
    color: $color-hover;
    background-color: $color;
    border-color: $color;
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active{
    color: $color-hover;
    background-color: $color;
    border-color: $color;
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  line-height: $line-height;
}

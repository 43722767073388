$result-list-price-color:  $accent;

// Results container

.result-container{
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $white;
  background-clip: border-box;
  border-radius: $border-radius;
  margin-bottom: theme-spaces(4);
  border: 1px solid $border-color;
}

.result-widget-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: calc(theme-spaces(4) / 2) theme-spaces(3);

  .result-widget-price{
    font-size: $h1-font-size;
    color: $result-list-price-color;
  }
}

.result-widget-footer{
  display: flex;
  justify-content: space-between;
  padding: calc(theme-spaces(4) / 2) theme-spaces(3);
}


// 
// Segments
// 

.segments {
  list-style: none;
  padding: 0;
  margin: 0;
}

.segment-wrapper {
  border-top: 1px solid $border-color;
}

.segment-item{
  position: relative;
  padding-left: theme-spaces(3);
  padding-right: theme-spaces(3);

  &:first-child{
    border-top: 0;
  }
}

.segment-item-duration{
  text-align: center;
  padding: theme-spaces(2) theme-spaces(3) !important;
}

.segment-details-content{
  padding: theme-spaces(3);
}

// 
// Connection
// 

.connection-info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $font-size-sm;
  line-height: $line-height-computed;
  padding: calc(theme-spaces(1) / 2) theme-spaces(3);
}


// Seat left indicator

.seats-left{
  position: absolute;
  top: 0;
  @if $text-direction == ltr {
    right: 0;
  } @else {
    left: 0;
  }
  width: 24px;
  height: 24px;
  border-style: solid;
  @if $text-direction == ltr {
    border-width: 0 24px 24px 0;
    border-color: transparent $danger transparent transparent;
  } @else {
    border-width: 24px 24px 0 0;
    border-color: $danger transparent transparent transparent;
  }

  > span{
    position: absolute;
    @if $text-direction == ltr {
      top: 1px;
      left: 14px;
    } @else {
      bottom: 10px;
      left: 4px;
    }
    font-size: 12px;
    line-height: 1;
    color: #fff;
  }
}

.seats-left-inner{
  border-bottom: 1px dashed $border-color;
  color: $danger;
  margin-bottom: theme-spaces(3);
}

// Metasearch
// 

.segment-group{
  padding: 0 theme-spaces(2);
  flex: 1;
  align-self: center;

  .segment-group-header {
      display: flex;
      align-items: center;
      padding: theme-spaces(1) 0 theme-spaces(3);
  }

  .segment-group-item{
    position: relative;
    padding: theme-spaces(3) theme-spaces(2);

     + .segment-group-item{
      &:before{
        content: '';
        display: block;
        border-top: 1px dashed $border-color;
        position: absolute;
        top: 0;
        left: theme-spaces(2);
        right: theme-spaces(2);
      }
    }
  }
}

.segment-group-item{

  .leg-time{
    font-size: 22px;
  }

  .airline-name{
    font-size: 12px;
    width: 60px;
  }

  .airport-name{
    @include media-breakpoint-up(md){
      font-size: $font-size-sm;
    }
  }


  .nex-day-arr{
    color: $danger;
    position: absolute;
    top: -.3em;
    @if $text-direction == ltr {
      left: 55px;
      @include media-breakpoint-up(sm){
        left: 90px;
      }
    } @else {
      right: 55px;
      @include media-breakpoint-up(sm){
        right: 90px;
      }
    }
  }
}

.differentAirport-badge,
.nextDay-badge{
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: $badge-font-size;
  line-height: 1;
  vertical-align: baseline;
  @include border-radius($badge-border-radius);
}

.differentAirport-badge{
  color: $body-color;
  background-color: theme-color-level(secondary, -10);
}

.nextDay-badge{
  color: $danger;
  background-color: theme-color-level(danger, -10);
}


.result-footer{
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  padding: theme-spaces(2) calc(theme-spaces(4) / 2);
  border-top: 1px solid $border-color;
  flex-wrap: wrap;

  @include media-breakpoint-up(md){
    padding-top: theme-spaces(3);
    padding-bottom: theme-spaces(3);
    border-top: 0;
    flex-direction: column;
    @if $text-direction == ltr {
      border-left: 1px solid $border-color;
    } @else {
      border-right: 1px solid $border-color;
    }
    .seat-left{
      margin-top: - theme-spaces(2);
    }
  }

}

.stop-indicator{
  position: relative;
  width: 100%;
  min-width: 64px;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;

  &:after{
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -1px;
    border-bottom: 2px solid lighten($gray-mid, 15%);
  }

  .icon-shape-oval-1{
    position: relative;
    color: $gray-mid;
    background: #fff;
    padding-top: theme-spaces(1);
    padding-bottom: theme-spaces(1);
    z-index: 1;
  }
}

$types: ('flight','rails','bus');

.transportation-types{
  display: flex;
  justify-content: space-around;
}

@each $type in $types{
  .transportation-type-#{$type}{
    &:before{
      display: inline-block;
      content: url('#{$img-path}/shared/types/ic_#{$type}.svg');
      vertical-align: middle;
    }
  }
}

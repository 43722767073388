#filters{
  .list-group-item{
    border-style: dashed;
  }
}

// Filter content
// 

.filter-content{
  margin-top: theme-spaces(3);

  .radio + .radio,
  .checkbox + .checkbox,
  .custom-checkbox + .custom-checkbox {
    margin-top: theme-spaces(1);
  }
}


// Filter toggle
// 

.filter-toggle{
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon-down-arrow-2 {
    font-size: $font-size-lg;
  }
}


// Show oly flag on hover
// 

.transportation-only,
.airline-only{
  color: $link-color;
  cursor: pointer;
  display: none;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: underline;
  }
}

.radio,
.checkbox,
.custom-checkbox{
  &:hover,
  &:focus{
    .transportation-only,
    .airline-only{
      display: inline-block;
    }
  }
}
// Kill the scroll on the body
.show-sidebar {
  overflow: hidden;
}

// Kill the scroll on the sidebar
.show-sidebar #sidebar {
  overflow: auto;
}

// Sidebar

#sidebar{
  overflow: hidden;
  transition: all .25s ease-out;

  @include media-breakpoint-down(md){
    position:fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $zindex-dropdown;
    background-color: $gray-light;
    padding: 0;
    transform: translateY(calc(100% - 40px));

    .card{
      margin: 20px 16px 16px;
    }
  }

  .card{
    .card-header {
      color: $primary;
      padding: 24px 16px;
    }

    &.trip-details {
      > div {

        &.p-3 {
          border-bottom: 1px solid $gray-light;
        }
      }
    }
  }

  .show-sidebar &{
    transform: translateY(0);

    .icon-up-arrow-2:not(.insidebar){
      transform: rotate(180deg);
    }
  }
}


//
// Sidebar Toggle
//

#sidebarButton,
#sidebarButtonToggle{
  display: none;
  align-items: center;
  justify-content: space-between;
  color: $white;
  font-weight: $font-weight-semibold;
  padding: theme-spaces(2) theme-spaces(4);
  background-color: $primary;

  //.show-sidebar &{
  //  background-color: $tertiary;
  //}

  @include media-breakpoint-down(md){
    display: flex;
  }
}
// 
// Form control focus state
//

@mixin form-control-focus() {
  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
  }
}


// 
// Form validation
// 

@mixin form-validation-state($state, $color){
  .#{$state}-message{
    display: block;
    color: $color;
    font-size: $font-size-sm;
    margin-top: calc(theme-spaces(1) / 2);
    @include animate(slide-top, 0.35s, ease-out);

    > ul{
      @include list-unstyled;
    }
  }

  .has-#{$state}{
    
    .form-control{
      border-color: $color;
    }

    &.input-icon > span{
      color: $color;
    }

    .form-icon{
      color: $color;
    }

    .custom-control-label{
      border-color: $color;
    }
    
    label{
      color: $color !important;
    }

    .checkbox,
    .radio{
      input[type="radio"]:not(:checked),
      input[type="checkbox"]:not(:checked) {
        border-color: $color;
      }
    }
    .checkbox{
      input[type="checkbox"]:not(:checked):after {
        display: none;
      }
    }
  }
  .checkbox,
  .radio{
    &.has-#{$state} {
      input[type="radio"]:not(:checked),
      input[type="checkbox"]:not(:checked) {
        border-color: $color;
      }
    }
  }
}

.dropdown-wrapper .input-icon > span{
  top: 17px;
  bottom: auto;
}

//
// Base styles
//

.input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;

  .form-control {
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;

    @include hover-focus-active {
      z-index: 2;
    }
  }
}

.input-group-btn,
.input-group .form-control {
  display: flex;
  align-items: center;

  &:not(:first-child):not(:last-child) {
    @include border-radius(0);
  }
}


.input-group-btn {
  white-space: nowrap;
}


// Sizing options
//
// Remix the default form control sizing classes into new ones for easier
// manipulation.

.input-group-lg > .form-control{
  @extend .form-control-lg;
}
.input-group-lg > .input-group-btn > .btn {
  @extend .btn-lg;
}


//
// Reset rounded corners
//

.input-group .form-control:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle){
  @if $text-direction == ltr {
    @include border-right-radius(0);
    @include border-left-radius($border-radius);
  } @else {
    @include border-left-radius(0);
    @include border-right-radius($border-radius);
  }
}

.input-group .form-control:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child){
  @if $text-direction == ltr {
    @include border-left-radius(0);
    @include border-right-radius($border-radius-lg);
  } @else {
    @include border-right-radius(0);
    @include border-left-radius($border-radius-lg);
  }
}


//
// Button input groups
//

.input-group-btn {
  position: relative;
  align-items: stretch;
  font-size: 0;
  white-space: nowrap;

  > .btn {
    position: relative;

    + .btn {
      @if $text-direction == ltr {
        margin-left: -1px;
      } @else {
        margin-right: -1px;
      }
    }

    @include hover-focus-active {
      z-index: 2;
    }
  }

  &:first-child > .btn + .btn {
    @if $text-direction == ltr {
      margin-left: 0;
    } @else {
      margin-right: 0;
    }
  }

  &:not(:last-child) {
    > .btn {
      @if $text-direction == ltr {
        margin-right: -1px;
      } @else {
        margin-left: -1px;
      }
    }
  }
  &:not(:first-child) {
    > .btn {
      z-index: 1;
      @if $text-direction == ltr {
        margin-left: 0;
      } @else {
        margin-right: 0;
      }

      &:first-child {
        @if $text-direction == ltr {
          margin-left: -1px;
        } @else {
          margin-right: -1px;
        }
      }

      @include hover-focus-active {
        z-index: 2;
      }
    }
  }
}


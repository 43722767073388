.next-steps {
  border: 1px dashed $gray-mid;

  img {
    position: absolute;
    top: -60px;
    left: calc(50% - 60px);
  }
}

.ship-details {
  position: relative;

  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -16px;
    bottom: 0;
    border-left: 2px dashed #d3d3d3;
  }
}

.toggle-ferry-segment-details {
  position: absolute;
  right: 8px;
  bottom: -23px;
  z-index: 1;
}

.pickup-marker {
  .icon-pin-3 {
    color: $text-muted;
    cursor: pointer;
    &:hover,
    &:focus {
      color: $ferries;
    }
  }
}

.banner-img-logo {
  position: absolute;
  right: 15px;
  bottom: -17px;
  width: 21%;
}

// widget welcomepickups

#welcomepickups {
  form {
    padding: 0 !important;
  }
}

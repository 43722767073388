$brand: 'tripsta' !default;

// Color system
//
//

$white:          #FFFFFF !default;
$black:          #000000 !default;
$primary:        #EA7600 !default;
$secondary:      #00A499 !default;
$tertiary:       #D9F8F3 !default;
$accent:         #EA7600 !default;
$success:        #4CAF50 !default;
$info:           #7EB4DB !default;
$warning:        #F0AD4E !default;
$danger:         #D9534F !default;
$body:           #F5F5F5 !default;
$gray-light:     #F5F5F5 !default;
$gray-mid:       #999999 !default;
$gray-dark:      #444444 !default;
$sp-table-row:   #F9F9F9 !default;
$snow:           #F9F9F9 !default;
$platinum:       #E4E4E4 !default;
$vivid-cerulean: #00A3E0 !default;
$cancelled:      #FDF3F3 !default;
$white-smoke:    #F2F2F2 !default;
$train:          #7EB4DB !default;
$helicopter:     #A696FC !default;
$mandatory-veh:  #BC7944 !default;

$facebook:       #4c70ba !default;
$twitter:        #00b9ff !default;
$google:         #dd4b39 !default;

$flight:         #00A499 !default;
$rails:          #84BD00 !default;
$ferries:        #00A3E0 !default;
$bus:            #734286 !default;
$alice-blue:     #F0F8FF !default;


$theme-colors: () !default;
$theme-colors: map-merge((
  'primary':       $primary,
  'secondary':     $secondary,
  'tertiary':      $tertiary,
  'accent':        $accent,
  'success':       $success,
  'info':          $info,
  'warning':       $warning,
  'danger':        $danger,
  'light':         $gray-light,
  'muted':         $gray-mid,
  'dark':          $gray-dark,
  'sp-table-row':  $sp-table-row,
  'platinum':      $platinum,
  'snow':          $snow,
  'cancelled':     $cancelled,
  'body':          $body,
  'train':         $train,
  'helicopter':    $helicopter,
  'mandatory-veh': $mandatory-veh
  
), $theme-colors);

$button-variant: (
  'primary':        $accent,
  'secondary':      $secondary,
  'tertiary':       $tertiary,
  'info':           $info,
  'vivid-cerulean': $vivid-cerulean,
  'danger':         $danger,
  'light':          #EBEBEB,
  'default':        $gray-light,
  'facebook':       $facebook,
  'twitter':        $twitter,
  'google':         $google
) !default;

$background-variant: (
  'primary':    $primary,
  'secondary':  $secondary,
  'tertiary':   $tertiary,
  'danger':     $danger,
  'light':      $gray-light,
  'dark':       $gray-dark,
  'white':      $white,
  'alice-blue': $alice-blue
) !default;

$alert-variant: (
  'success':    $success,
  'info':       $info,
  'warning':    $warning,
  'danger':     $danger
) !default;

$type-colors: (
  'flight':   $flight,
  'rails':    $rails,
  'ferries':  $ferries,
  'bus':      $bus
) !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;

// Customize the light and dark text colors for use in color contrast function.
$yiq-text-dark:  $gray-dark;
$yiq-text-light: $white;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$text-direction:    ltr !default;
$enable-shadows:    true !default;


// Webpack dependecies
// 
// 

$img-path:  '../../../images';


// Spacing
//
//

$spacer: 16px;

$spaces: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 3),
  7: ($spacer * 4),
  8: ($spacer * 5)
);


// Sizing
//
//

$sizes: (
  '1-1': (1,1), // 100%
  '1-2': (1,2), // 50%
  '1-3': (1,3), // 33.33333333%
  '2-3': (2,3), // 66.66666667%
  '1-4': (1,4), // 25%
  '3-4': (3,4), // 75%
  '1-10': (1,10), // 10%
  '2-10': (2,10), // 20%
  '3-10': (3,10), // 30%
  '4-10': (4,10), // 40%
  '6-10': (6,10), // 60%
  '7-10': (7,10), // 70%
  '8-10': (8,10), // 80%
  '9-10': (9,10)  // 90%
);


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1144px
);


// Body
//
// Settings for the `<body>` element.

$body-bg:       $body !default;
$body-color:    $gray-dark !default;


// Links
//
// Style anchor elements.

$link-color:                $vivid-cerulean !default;
$link-decoration:           none;
$link-hover-color:          darken($link-color, 15%);
$link-hover-decoration:     underline;


// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   theme-spaces(3) !default;


// Grid columns
//
// Specify the width of the gutters.

$grid-gutter-width:         theme-spaces(5);
$grid-gutter-width-small:   theme-spaces(3);
$grid-gutter-width-tiny:    theme-spaces(2);


// Components
//
// Define common padding and border radius sizes and more.

$line-height-sm:              1.25 !default;
$line-height-lg:              2 !default;

$border-color:                rgba($black, .125) !default;
$shadow:                      0 1px 2px rgba($black, 0.05), 0 0 0 1px rgba($black, 0.1);

$border-radius:               4px !default;
$border-radius-lg:            8px !default;
$border-radius-sm:            2px !default;

$component-hover-color:       $gray-light !default;
$component-active-color:      $white !default;
$component-active-bg:         $vivid-cerulean !default;

$caret-width:                 .3em !default;

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;
$zindex-popover:                    1060 !default;
$zindex-tooltip:                    1070 !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          calc(theme-spaces(4) / 2)  !default;
$table-cell-padding-sm:       theme-spaces(1) !default;

$table-bg:                    $white !default;
$table-accent-bg:             $gray-light !default;
$table-hover-bg:              $gray-light !default;
$table-active-bg:             $table-hover-bg !default;

$table-border-width:          1px !default;
$table-border-color:          $border-color !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
$font-family-base:            $font-family-sans-serif !default;

$type-scale:                  1.125 !default;
$type-scale-display:          1.25 !default;

$font-size-base:              16px !default;
$font-size-lg:                floor($font-size-base * $type-scale) !default;
$font-size-sm:                floor(calc($font-size-base / $type-scale)) !default;
$font-size-xs:                floor(calc($font-size-base / pow($type-scale, 2))) !default;

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-semibold:        500 !default;
$font-weight-bold:            700 !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;
$line-height-computed:        ($line-height-base * $font-size-base) !default;


$h1-font-size:                floor($font-size-base * pow($type-scale, 4)) !default;
$h2-font-size:                floor($font-size-base * pow($type-scale, 3)) !default;
$h3-font-size:                floor($font-size-base * pow($type-scale, 2)) !default;
$h4-font-size:                floor($font-size-base * $type-scale) !default;
$h5-font-size:                floor($font-size-base) !default;
$h6-font-size:                floor(calc($font-size-base / $type-scale)) !default;

$display1-size:               floor($font-size-base * pow($type-scale-display, 5)) !default;
$display2-size:               floor($font-size-base * pow($type-scale-display, 4)) !default;
$display3-size:               floor($font-size-base * pow($type-scale-display, 3)) !default;
$display4-size:               floor($font-size-base * pow($type-scale-display, 2)) !default;

$headings-margin-bottom:      theme-spaces(2) !default;
$headings-font-family:        inherit !default;
$headings-font-weight:        $font-weight-semibold !default;
$headings-line-height:        1.2 !default;
$headings-color:              inherit !default;

$text-muted:                  $gray-mid !default;

$hr-border-color:             rgba($black, .1) !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-x:               theme-spaces(4) !default;
$btn-padding-y:               calc($btn-padding-x / 2) !default;
$btn-line-height:             $line-height-sm !default;

$btn-padding-y-sm:            6px !default;
$btn-padding-x-sm:            12px !default;
$btn-line-height-sm:          $line-height-base !default;

$btn-padding-y-lg:            theme-spaces(2) !default;
$btn-padding-x-lg:            theme-spaces(4) !default;
$btn-line-height-lg:          $line-height-lg !default;

$btn-font-weight:             $font-weight-semibold !default;

$btn-disabled-opacity:        .65 !default;
$btn-link-disabled-color:     $text-muted !default;

$btn-border-radius:           $border-radius !default;
$btn-border-radius-lg:        $border-radius-lg !default;
$btn-border-radius-sm:        $border-radius-sm !default;

$btn-block-spacing-y:         theme-spaces(1);

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out !default;

$btn-icon-width:              3rem;

// Forms

$input-padding-y:             10px !default;
$input-padding-x:             calc(theme-spaces(4) / 2) !default;
$input-line-height:           $line-height-base !default;

$input-padding-y-lg:          theme-spaces(2) !default;
$input-padding-x-lg:          theme-spaces(3) !default;
$input-line-height-lg:        $line-height-lg !default;

$input-bg:                    $white !default;
$input-disabled-bg:           $gray-light !default;

$input-color:                 $gray-dark !default;
$input-border-color:          $border-color !default;

$input-border-radius:         $border-radius !default;
$input-border-radius-lg:      $border-radius-lg !default;
$input-border-radius-sm:      $border-radius-sm !default;

$input-focus-bg:              $input-bg !default;
$input-focus-border-color:    $vivid-cerulean !default;
$input-focus-color:           $input-color !default;

$input-placeholder-color:     $text-muted !default;

$input-transition:            border-color .15s ease-in-out !default;

$input-height-inner:          ($input-line-height * $font-size-base) + ($input-padding-y * 2) !default;
$input-height:                $input-height-inner + 2px !default;

$input-height-inner-lg:       ($input-line-height-lg * $font-size-lg) + ($input-padding-y-lg * 2) !default;
$input-height-lg:             $input-height-inner-lg + 2px !default;

$form-group-margin-bottom:    $spacer !default;

$form-check-input-active-bg:  $vivid-cerulean !default;
$form-range-input-active-bg:  $primary !default;

$input-icon-width:            3rem;

$toggle-switch-gutter:        3px;
$toggle-switch-slider-size:   18px;


// Close

$close-font-size:             $font-size-base * 1.5 !default;
$close-font-weight:           $font-weight-normal !default;
$close-color:                 $black !default;


// Cards

$card-spacer-y:               theme-spaces(3) !default;
$card-spacer-x:               theme-spaces(3) !default;
$card-border-radius:          $border-radius !default;
$card-border-color:           $border-color !default;
$card-cap-bg:                 $border-color !default;
$card-bg:                     $white !default;

$card-img-overlay-padding:    1.25rem !default;

$card-margin:                 theme-spaces(3) !default;


// List group

$list-group-bg:                     $white !default;
$list-group-border-color:           $border-color !default;
$list-group-border-width:           1px !default;
$list-group-border-radius:          $border-radius !default;

$list-group-item-padding-x:         theme-spaces(3) !default;
$list-group-item-padding-y:         calc(theme-spaces(4) / 2) !default;

$list-group-hover-bg:               $gray-light !default;
$list-group-active-color:           $component-active-color !default;
$list-group-active-bg:              $component-active-bg !default;
$list-group-active-border-color:    $list-group-active-bg !default;

$list-group-disabled-color:         $text-muted !default;
$list-group-disabled-bg:            $list-group-bg !default;

$list-group-action-color:           $gray-dark !default;
$list-group-action-hover-color:     $list-group-action-color !default;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-x:                   theme-spaces(4) !default;
$alert-padding-y:                   calc($alert-padding-x / 2) !default;
$alert-margin-bottom:               $spacer !default;
$alert-border-radius:               $border-radius !default;
$alert-border-color:                $border-color !default;
$alert-icon-size:                   $font-size-base * 1.125;


// Navs

$nav-link-padding-y:                theme-spaces(2) !default;
$nav-link-padding-x:                theme-spaces(3) !default;
$nav-link-disabled-color:           $text-muted !default;
$nav-link-color:                    $body-color !default;
$nav-link-hover-color:              $text-muted !default;
$nav-link-active-color:             $body-color !default;

$nav-tabs-border-color:             $border-color !default;
$nav-tabs-border-width:             1px !default;
$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-active-bg:           $white !default;

$nav-pills-border-radius:           $border-radius !default;
$nav-pills-link-active-color:       $component-active-color !default;
$nav-pills-link-active-bg:          $component-active-bg !default;


// Navbar

$navbar-padding-y:            calc($spacer / 2) !default;
$navbar-padding-x:            $spacer !default;

$navbar-bg:                   $white !default;

$navbar-link-disabled-color:  $text-muted !default;
$navbar-link-color:           $body-color !default;
$navbar-link-hover-color:     $text-muted !default;
$navbar-link-active-color:    $body-color !default;

$navbar-link-padding-x:       theme-spaces(2) !default;
$navbar-link-padding-y:       theme-spaces(2) !default;

$navbar-brand-padding-y:      $navbar-padding-y !default;
$navbar-brand-font-size:      $font-size-lg !default;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:                160px !default;
$dropdown-padding-y:                theme-spaces(2) !default;
$dropdown-spacer:                   theme-spaces(1) !default;
$dropdown-bg:                       $white !default;
$dropdown-border-color:             $border-color !default;
$dropdown-border-radius:            $border-radius !default;
$dropdown-border-width:             1px !default;
$dropdown-divider-bg:               $border-color !default;
$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175) !default;

$dropdown-link-color:               $body-color !default;
$dropdown-link-hover-color:         darken($body-color, 5%) !default;
$dropdown-link-hover-bg:            $component-hover-color !default;

$dropdown-link-active-color:        $component-active-color !default;
$dropdown-link-active-bg:           $component-active-bg !default;

$dropdown-link-disabled-color:      $text-muted !default;

$dropdown-item-padding-y:           theme-spaces(1) !default;
$dropdown-item-padding-x:           theme-spaces(4) !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding:               theme-spaces(3) !default;

$modal-dialog-margin:               10px !default;
$modal-dialog-margin-y-sm-up:       30px !default;

$modal-title-line-height:           $line-height-base !default;

$modal-content-bg:                  $white !default;
$modal-content-border-color:        rgba($black, .2) !default;
$modal-content-border-width:        1px !default;
$modal-content-box-shadow-xs:       0 3px 9px rgba($black, .5) !default;
$modal-content-box-shadow-sm-up:    0 5px 15px rgba($black, .5) !default;

$modal-backdrop-bg:                 $black !default;
$modal-backdrop-opacity:            .5 !default;
$modal-header-border-color:         $border-color !default;
$modal-footer-border-color:         $modal-header-border-color !default;
$modal-header-border-width:         $modal-content-border-width !default;
$modal-footer-border-width:         $modal-header-border-width !default;
$modal-header-padding:              15px !default;

$modal-lg:                          800px !default;
$modal-md:                          560px !default;
$modal-sm:                          360px !default;

$modal-transition:                  transform .3s ease-out !default;


// Popovers

$popover-bg:                          $white !default;
$popover-max-width:                   276px !default;
$popover-border-color:                $border-color !default;

$popover-title-bg:                    $gray-light !default;

$popover-arrow-width:                 10px !default;
$popover-arrow-color:                 $popover-bg !default;
$popover-arrow-outer-width:           ($popover-arrow-width + 1) !default;
$popover-arrow-outer-color:           fade_in($popover-border-color, 0.05) !default;


// Badges

$badge-font-size:                   0.75rem !default;
$badge-font-weight:                 $font-weight-bold !default;
$badge-padding-y:                   0 !default;
$badge-padding-x:                   0.5rem !default;
$badge-size:                        1.25rem !default;
$badge-border-radius:               $border-radius !default;

$badge-pill-border-radius:          100px !default;

// Outline
$outline: lighten($gray-mid, 15%);

//
// Unstyled list
//

.list-unstyled {
  @include list-unstyled;
}


//
// Inline list
// Inline turns list items into inline-block

.list-inline {
  @include list-unstyled;

  > li{
    display: inline-block;
    
    &:not(:last-child){
      @if $text-direction == ltr {
        margin-right: theme-spaces(2);
      } @else {
        margin-left: theme-spaces(2);
      }
    }
  }

  &.divider{
  > li + li{
      &:before{
        content: "";
        display: inline-block;
        vertical-align: baseline;
        height: 1em;
        @if $text-direction == ltr {
          margin-right: theme-spaces(2);
        } @else {
          margin-left: theme-spaces(2);
        }
        border-left: 1px solid $border-color;
      }
    }
  }
}

// 
// Striped list
// 

.list-striped{
  @include list-unstyled;

  > li{
    padding: theme-spaces(1);

    &:nth-of-type(odd){
      background-color: $gray-light;
    }
  }
}

// 
// Check list
// 

.check-list{
  @include list-unstyled;

  > li{
    position: relative;
    @if $text-direction == ltr {
      padding-left: theme-spaces(4);
    } @else {
      padding-right: theme-spaces(4);
    }

    &:before{
      font-family: Nucleo;
      content: '\ea53';
      color: theme-color('success');
      position: absolute;
      @if $text-direction == ltr {
        left: 0;
      } @else {
        right: 0;
      }
    }
  }
}
.result-details-arr{
  &:before{
    background-color: $white !important;
  }
}


.flight-stop {
  .result-details-stop-info:before{
    top: -4em;
    @if $text-direction == ltr {
      left: calc($type-icon-size / 2) - 1;
    } @else {
      right: calc($type-icon-size / 2) - 1;
    }
  }
}
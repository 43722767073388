$timeline-default-bg: #ccc;
$timeline-current-bg: $secondary;

//
// Timeline section
//

.timeline{
  position: relative;
}


// Timeline Rows
.timeline-entry-row{
  position: relative;
  padding-top: 4em;
  padding-bottom: theme-spaces(3);
  padding-left: 80px;

  &:before,
  &:after{
    content: '';
    position: absolute;
    background-color: $timeline-default-bg;
  }

  &:before{
    top: 0;
    left: 30px;
    bottom: 0;
    width: 4px;
  }

  &:after{
    top: 5em;
    left: 24px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #fff;
  }

  @include media-breakpoint-up(lg){
    padding-left: 0;
    padding-bottom: 0;

    &:before,
    &:after{
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.timeline-entry-row.current{
  &:before,
  &:after{
    background-color: $timeline-current-bg;
  }
}


// Timeline Year

.timeline-year{
  display: inline-block;
  padding: theme-spaces(2) theme-spaces(3);
  color: #fff;
  background-color: $timeline-default-bg;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;

  .current &{
    background-color: $timeline-current-bg;
  }

  @include media-breakpoint-up(lg){
    left: 50%;
    transform: translateX(-50%);
  }
}


// Slider

.timelineSlider{
  .slick-slide,
  .bg-cover{
    height: 160px;

    @include media-breakpoint-up(md){
      height: 480px;
    }
  }

  // create previous/next icon navigations

  .slidenav {
    padding: 5px;
    opacity: .5;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    @include hover-focus{
      opacity: 1;
      cursor: pointer;
    }
  }

  .slidenav-prev{
    left: 1em;
  }

  .slidenav-next{
    right: 1em;
  }
}

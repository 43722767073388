.seat-class-details-slider {
  .slick-slider {
    .slick-arrow {
      background: $white;
      border-radius: $border-radius;
      padding: (theme-spaces(1) + 2) theme-spaces(1);
    }

    .slick-next {
      right: 12px;
    }

    .slick-prev {
      left: 12px;
    }
  }
}

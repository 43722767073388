// Fade
// -----------------------------------


@keyframes fade {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

// Fade with slide
// -----------------------------------


// Top

@keyframes slide-top {
	0% {
		opacity: 0;
		transform: translate(0, -100%);
	}
	100% {
		opacity: 1;
		transform: translate(0, 0);
	}
}


// Bottom

@keyframes slide-bottom {
	0% {
		opacity: 0;
		transform: translate(0, 100%);
	}
	100% {
		opacity: 1;
		transform: translate(0, 0);
	}
}


// Left

@keyframes slide-left {
	0% {
		opacity: 0;
		transform: translate(-100%, 0);
	}
	100% {
		opacity: 1;
		transform: translate(0, 0);	
	}
}


// Right

@keyframes slide-right {
	0% {
		opacity: 0;
		transform: translate(100%, 0);
	}
	100% {
		opacity: 1;
		transform: translate(0, 0);
	}
}


// Fade wth scale
// -----------------------------------

@keyframes fade-scale {
  0% {
      opacity: 0;
      transform: scale(.2)
  }

  to {
      opacity: 1;
      transform: scale(1)
  }
}

// Rotate
// -----------------------------------

@keyframes rotate {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(359deg); }
}

// Spin
// -----------------------------------

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  color: $headings-color;
  line-height: $headings-line-height;
}

h1, .h1 { font-size: $h1-font-size; }
h2, .h2 { font-size: $h2-font-size; }
h3, .h3 { font-size: $h3-font-size; }
h4, .h4 { font-size: $h4-font-size; }
h5, .h5 { font-size: $h5-font-size; }
h6, .h6 { font-size: $h6-font-size; }

.text-lead {
  font-size: $font-size-lg;
}


// Type display classes

.display-1 {
  font-size: $display1-size;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-2 {
  font-size: $display2-size;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-3 {
  font-size: $display3-size;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}
.display-4 {
  font-size: $display4-size;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}


// 
// Page title
// 

.page-title{
  display: flex;
  align-items: center;
  padding-bottom: theme-spaces(2); 
  margin-bottom: theme-spaces(4);
  border-bottom: 1px solid $border-color;
}

//
// Horizontal rules
//

hr {
  margin-top: theme-spaces(2);
  margin-bottom: theme-spaces(2);
  border: 0;
  border-top: 1px solid $hr-border-color;
}


//
// separator text
//

.separator-text {
    position: relative;
    text-align: center;
    margin: $spacer 0
}

.separator-text > span {
    font-size: $font-size-sm;
    background-color: $white;
    display: inline-block;
    vertical-align: top;
    position: relative;
    z-index: 5;
    padding: 0 $spacer;
}

.separator-text:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  border-bottom: 1px solid $border-color;
}


// 
// Best Value Label
// 

.best-value-label{
  position: absolute;
  top: -11px;
  left: 0;
  right: 0;

  @include media-breakpoint-down(md) {
    position: static;
    top: unset;
    left: unset;
    right: unset;
  }

  > span{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    font-size: $font-size-xs;
    color: $white;
    font-weight: bold;
    padding-left: theme-spaces(1);
    padding-right: theme-spaces(1);
    border: 1px solid $success;
    background-color: $success;
    border-radius: 3px;
    white-space: nowrap;

    @include media-breakpoint-down(md) {
      position: static;
      transform: unset;
    }
  }

  @include media-breakpoint-up(md) {
    &:before {
      content: '';
      height: 1px;
      background-color: $success;
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
    }
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

// Container
//
// Set the page width.

@mixin make-container() {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: calc($grid-gutter-width / 2);
  padding-left: calc($grid-gutter-width / 2);
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}


// Grid system
//
// Generate semantic grid columns

@mixin make-row() {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc($grid-gutter-width / -2);
  margin-left: calc($grid-gutter-width / -2);
}

@mixin make-col-ready() {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: calc($grid-gutter-width / 2);
  padding-left: calc($grid-gutter-width / 2);
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage(calc($size / $columns));
  max-width: percentage(calc($size / $columns));
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  @if $text-direction == ltr {
    margin-left: percentage(calc($size / $columns));
  } @else {
    margin-right: percentage(calc($size / $columns));
  }
}
//@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700,800');

//$font-path : '../../../fonts';
//
///* open-sans-300 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 300;
//  font-display: auto;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-300.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Light'), local('OpenSans-Light'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-regular - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 400;
//  font-display: auto;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-regular.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Regular'), local('OpenSans-Regular'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-italic - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: italic;
//  font-weight: 400;
//  font-display: auto;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-italic.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Italic'), local('OpenSans-Italic'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-600 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 600;
//  font-display: auto;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-600.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-700 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 700;
//  font-display: auto;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-700.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Bold'), local('OpenSans-Bold'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-800 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 800;
//  font-display: auto;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-800.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
//
///* open-sans-300 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 300;
//  font-display: swap;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-300.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Light'), local('OpenSans-Light'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-regular - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 400;
//  font-display: swap;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-regular.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Regular'), local('OpenSans-Regular'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-italic - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: italic;
//  font-weight: 400;
//  font-display: swap;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-italic.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Italic'), local('OpenSans-Italic'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-600 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 600;
//  font-display: swap;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-600.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-700 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 700;
//  font-display: swap;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-700.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Bold'), local('OpenSans-Bold'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-800 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 800;
//  font-display: swap;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-800.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
//
///* open-sans-300 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 300;
//  font-display: block;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-300.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Light'), local('OpenSans-Light'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-regular - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 400;
//  font-display: block;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-regular.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Regular'), local('OpenSans-Regular'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-italic - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: italic;
//  font-weight: 400;
//  font-display: block;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-italic.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Italic'), local('OpenSans-Italic'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-600 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 600;
//  font-display: block;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-600.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-700 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 700;
//  font-display: block;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-700.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Bold'), local('OpenSans-Bold'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-800 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 800;
//  font-display: block;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-800.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
//
///* open-sans-300 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 300;
//  font-display: fallback;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-300.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Light'), local('OpenSans-Light'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-regular - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 400;
//  font-display: fallback;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-regular.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Regular'), local('OpenSans-Regular'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-italic - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: italic;
//  font-weight: 400;
//  font-display: fallback;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-italic.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Italic'), local('OpenSans-Italic'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-600 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 600;
//  font-display: fallback;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-600.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-700 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 700;
//  font-display: fallback;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-700.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Bold'), local('OpenSans-Bold'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-800 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 800;
//  font-display: fallback;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-800.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
//
///* open-sans-300 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 300;
//  font-display: optional;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-300.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Light'), local('OpenSans-Light'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-regular - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 400;
//  font-display: optional;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-regular.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Regular'), local('OpenSans-Regular'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-italic - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: italic;
//  font-weight: 400;
//  font-display: optional;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-italic.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Italic'), local('OpenSans-Italic'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-600 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 600;
//  font-display: optional;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-600.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-700 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 700;
//  font-display: optional;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-700.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Bold'), local('OpenSans-Bold'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
///* open-sans-800 - latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 800;
//  font-display: optional;
//  src: url('#{$font-path}/airtickets/open-sans-v16-latin-800.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.woff') format('woff'), /* Modern Browsers */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
//       url('#{$font-path}/airtickets/open-sans-v16-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
//}

$brand: 'airtickets';

// Color system
//
//

$white:         #FFFFFF;
$black:         #000000;
$primary:       #00339A;
$secondary:     #00AEEF;
$tertiary:      #00AEEF;
$accent:        #ED0087;
// $success:       #87CB16;
$warning:       #FF9500;
$danger:        #FF4A55;
$gray-light:    #EDEDED;
$gray-mid:      #8F96A3;
$gray-dark:     #4A566F;

$flight:        #00339A;
$rails:         #009995;
$ferries:       #00A3E0;
$bus:           #734286;


// Body
$body-color:    #4D4D4D;
$body-bg:       #E4E5E9;


// Components

$border-color:  rgba($black, .125);


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:      "Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif !default;

$font-weight-semibold:        600 !default;
$font-weight-bold:            700 !default;


// Forms
$input-border-color:          #e3e5e8;
$input-focus-border-color:    $tertiary;
$input-disabled-bg:           #f5f5f5;

// Cards
$at-card-border-top:     3px solid $secondary;
// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
//

*,
*::before,
*::after {
  box-sizing: border-box;
}

// Flexbox support

// * {
//   min-height: 0;
//   min-width: 0;
// }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// IE10+ doesn't honor `<meta name="viewport">` in some cases.
@at-root {
  @-ms-viewport {
    width: device-width;
  }
}

// Shim for "new" HTML5 structural elements to display correctly (IE10, older browsers)
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}


// 
// Body
// 1. Set an explicit initial text-align value so that we can later use the
//    the `inherit` value on things like `<th>` elements.
//    There are two new values in CSS3 as well, start and end. 
//    These values make multiple language support easier
//    https://css-tricks.com/almanac/properties/t/text-align/
// 2. Popover bug with focus trigger undismissible on iOS/Safari
//    https://github.com/twbs/bootstrap/issues/16028

body {
  margin: 0;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $body-color;
  text-align: left; //1
  text-align: start;
  @if $text-direction == ltr {
    direction: ltr;
  } @else {
    direction: rtl;
  }
  background-color: $body-bg;

  @include media-breakpoint-down(sm){
    cursor: pointer; //2
  }
}

// Suppress the focus outline on elements that cannot be accessed via keyboard.
[tabindex="-1"]:focus {
  outline: 0 !important;
}


// 
// Content grouping
//

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}


//
// Typography
//

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: $headings-margin-bottom;
}

p {
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

b,
strong {
  font-weight: bold;
}

small {
  font-size: $font-size-sm;
  line-height: $line-height-computed;
}

u, ins {
  text-decoration: none;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub { bottom: -.25em; }
sup { top: -.5em; }

//
// Links
//

a {
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;

  @include hover {
    cursor: pointer;
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

a:not([href]):not([tabindex]) {
  // color: inherit;
  text-decoration: none;
  
  @include hover{
    cursor: pointer;
  }

  @include hover-focus {
    // color: inherit;
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
}


//
// Screenreaders
//

.sr-only {
  position: absolute;
  @include text-hide;
}


//
// Figures
//

figure {
  // Apply a consistent margin strategy (matches our type styles).
  margin: 0 0 1rem;
}


//
// Images and content
//

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}


//
// Tables
//

table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
}


//
// Forms
//

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  cursor: pointer;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

input:-webkit-autofill, 
textarea:-webkit-autofill, 
select:-webkit-autofill {
  background-color: inherit;
  background-image: inherit;
  color: inherit;
}


//
// Correct element displays
//

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

// Always hide an element with the `hidden` HTML attribute (from PureCSS).
// Needed for proper display in IE 10-.
[hidden] {
  display: none !important;
}

.leaflet-container {
  height: 350px;
}

.mini-rectangle-line {
  height: 4px;
  width: 34px;
}

#landing-ferry-company {
  .leaflet-container {
    width: 100%;
    height: 500px;
  }

  .learn-more {
    height: 240px;
    overflow: hidden;
  }

  .atlas-btn {
    margin: auto;
    left: 0;
    right: 0;
    bottom: 50px;
    width: 290px;
    z-index: 500;
  }

  .scrollable-popup {
    max-height: 290px;
    overflow: auto;
  }
}

#landing-ferry-vessel {
  .provision {
    .images {
      max-width: 160px;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .breadcrump {
    width: 100%;
    background-color: rgba(52, 77, 144, 0.7);
    padding-left: 8%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }

  @media (max-width: 768px) {
    .overflow-img {
      min-width: 240px;
      height: 150px;
      object-fit: cover;
    }
  }
}

#blog-main {
  @media (min-width: 768px) {
    .main-post {
      max-width: 462px;
    }

    .post-switch {
      .unequal-width {
        width: calc(60% - 16px);
      }
    }
  }
}

#faqs-main {
  .useful-article-line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .title-autosuggest {
    margin-bottom: -45px;
  }

  .faq-searh-autosuggest {
    box-shadow: 0px 6px 12px rgba(53, 62, 68, 0.25);
  }

  .category-card-body {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

#destination-destination {
  .searchMask-tab-wrapper {
    @include media-breakpoint-down(sm) {
      margin-top: -45px !important;
    }
  }


  #destination-gallery-slider {
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;

    img {
      width: 100%;
      height: 240px;

      @include media-breakpoint-up(sm) {
        width: 460px;
        height: 400px;
      }

      @include media-breakpoint-up(md) {
        width: 650px;
        height: 350px;
      }

      @include media-breakpoint-up(lg) {
        width: 900px;
        height: 500px;
      }

      @include media-breakpoint-up(xl) {
        width: 1110px;
        height: 500px;
      }
    }

    .slick-arrow {
      width: 50px;
      background: rgba(255, 255, 255, 0.70);
      height: 50px;
      border-radius: 260px;
      padding-bottom: 16px;
    }

    .slick-arrow.slick-prev {
      left: -12px;
      @include media-breakpoint-up(sm) {
        left: calc((100% - 540px)/2);
      }
      @include media-breakpoint-up(md) {
        left: calc((100% - 720px)/2);
      }
      @include media-breakpoint-up(lg) {
        left: calc((100% - 960px)/2);
      }
      @include media-breakpoint-up(xl) {
        left: calc((100% - 1160px)/2);
      }

      &:before {
        position: absolute;
        top: 17px;
        left: 17px;
      }
    }

    .slick-arrow.slick-next {
      right: -12px;
      @include media-breakpoint-up(sm) {
        right: calc((100% - 540px)/2);
      }
      @include media-breakpoint-up(md) {
        right: calc((100% - 720px)/2);
      }
      @include media-breakpoint-up(lg) {
        right: calc((100% - 960px)/2);
      }
      @include media-breakpoint-up(xl) {
        right: calc((100% - 1160px)/2);
      }

      &:before {
        position: absolute;
        top: 17px;
        left: 17px;
      }
    }
  }
}

#ferry-vessels {
  .ferry-vessel-cardbody:hover {
    background-color: $snow;
  }
  .icon-broken-heart {
    font-size: 44px;
  }
}

#ferry-routes-page {
  .icon-broken-heart {
    font-size: 44px;
  }
}

#special-prices-offers, #cabin-seat-page {
  .card.card-shadow {
    box-shadow: 0 2px 10px rgba(53, 62, 68, 0.25);
  }
}

#special-prices-offers {
  .price-hero-title {
    h1 span {
      display: block;
    }
  }
}

$links: ('flights', 'hotels', 'fh', 'cars', 'ferries', 'cruises', tours_activities);

.navbar{
  border-bottom: 1px solid $border-color;

  @include media-breakpoint-up(lg){
    .navbar-nav.navbar-nav-main{
      > li{
        position: relative;
        display: flex;
        align-items: center;
      }

      @each $item in $links {
        .navbar-link.#{$item}{
          font-size: $font-size-xs;
          text-align: center;
          line-height: 24px;
          padding-top: 0;
          padding-bottom: 0;

          &:before{
            content: url(../../../images/airtickets/navbar/#{$item}.svg);
            display: block;
            width: 48px;
            height: 34px;
            opacity: .5;
            margin: 0 auto;
          }
        }
      }

      > li.active{

        &:after{
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          border-top: 0;
          border-right: 6px solid transparent;
          border-bottom: 6px solid #646464;
          border-left: 6px solid transparent;
        }
      }

      > li.active > .navbar-link{
        &:before{
          opacity: .8;
        }
        @include plain-hover-focus{
          @include text-hide;
        }
      }
    }  
  }
}

// Navbar
//
// Use it to elements that are placed after navbar
.append-to-navbar{
  background-color: darken($primary, 10%) !important;
}


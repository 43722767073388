// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s or `<ul>`s.

.nav {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
}

.nav-link {
  display: block;
  color: $nav-link-color;
  padding: $nav-link-padding-y $nav-link-padding-x;

  @include hover-focus {
    color: darken($nav-link-color, 15%);
    text-decoration: none;
  }

  // Disabled state lightens text
  &.disabled {
    color: $nav-link-disabled-color;
  }
}

//
// Tabs
//

.react-tabs {
  .nav-item {
    &:focus-visible {
      outline: none;
    }
  }
}

.nav-tabs {
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

  .nav-item {
    margin-bottom: -$nav-tabs-border-width;
  }

  .nav-link {
    border: $nav-tabs-border-width solid transparent;
    @include border-top-radius($nav-tabs-border-radius);

    &.disabled {
      color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.active > .nav-link {
    &:not(.disabled){
      color: $nav-link-active-color;
      background-color: $nav-tabs-link-active-bg;
      border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-link-active-bg;
    }
  }
}


//
// Pills
//

.nav-pills {
  .nav-link.active,
  .nav-item.active > .nav-link {
    &:not(.disabled){
      color: $nav-pills-link-active-bg;
      border-bottom: 4px solid $nav-pills-link-active-bg;
    }
    &.disabled{
      border-bottom: 4px solid transparent;
    }
  }

  .selected.nav-link {
    &::after {
      content: '';
      display: block;
      height: 4px;
      margin: 10px auto 0;
    }

    &:not(.disabled){
      color: $nav-pills-link-active-bg;

      &::after {
        background-color: $nav-pills-link-active-bg;
      }
    }
    &.disabled{
      &::after {
        background-color: transparent;
      }
    }
  }

  .invalid {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.outline {
  ul.nav {
    .nav-item {
      margin-bottom: -1px;
      border-bottom: 1px solid $outline;

      &.active {
        border: 1px solid $outline;
        border-bottom: 1px solid $white;
      }
    }
  }

  .tab-content {
    border: 1px solid $outline;
  }
}

//
// Decoration
//

.nav-decoration {
  .nav-item {
    margin: theme-spaces(2);
    padding-bottom: theme-spaces(1);
    color: $primary;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &.active {
      font-weight: bold;
      border-bottom: 2px solid $primary;
    }
  }
}

//
// Justified variants
//

.nav-fill {
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}


// 
// Nav divider
// 

.nav-divider{
  height: 0;
  margin: calc($spacer / 2) 0;
  overflow: hidden;
  border-top: 1px solid $border-color;
}


// 
// Nav header
// 

.nav-header {
  display: block;
  padding: calc($nav-link-padding-y / 2) $nav-link-padding-x;
  margin-bottom: 0; // for use with heading elements
  font-size: $font-size-sm;
  color: $text-muted;
  white-space: nowrap;
}


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}

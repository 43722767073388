// 
// Branded Fare Modal
// 

.branded-fare-table{
  position: relative;
}

// Aside
.branded-fare-table-aside{
  width: 160px;
  background-color: $gray-light;
  position: absolute;
  top: 0;
  bottom: 0;
  @if $text-direction == ltr {
    left: 0;
    border-right: 1px solid $border-color;
  } @else {
    right: 0;
    border-left: 1px solid $border-color;
  }

  @include media-breakpoint-up(md){
    width: 240px;
  }
}

.branded-fare-table-aside{
  .list-group-item{
    background-color: $gray-light;
    @include text-truncate
  }
  .benefits{
    line-height: $line-height-computed * 2;
  }
}

// Main content

.branded-fare-table-container{
  position: relative;
  width: 100%;
}

.branded-fare-table-wrapper{
  display: flex;
  overflow-x: auto;
  @if $text-direction == ltr {
    margin-left: 160px;
  } @else {
    margin-right: 160px;
  }

  @include media-breakpoint-up(md){
    @if $text-direction == ltr {
      margin-left: 240px;
    } @else {
      margin-right: 240px;
    }
  }
}

.branded-fare-table-column{
    flex: 0 0 auto;
    width: auto;
    max-width: none;

  &:nth-child(n+2){
    @if $text-direction == ltr {
      border-left: 1px solid $border-color;
    } @else {
      border-right: 1px solid $border-color;
    }
  }
}

.branded-fare-table-column .badge{
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 0 4px 4px;
  padding-top: 2px;
  padding-bottom: 2px;
}

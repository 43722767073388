// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.

.custom-control {
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  @if $text-direction == ltr {
    margin-right: theme-spaces(1);
  } @else {
    margin-left: theme-spaces(1);
  }
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-label {
  position: relative;
  width: 18px;
  height: 18px;
  background: $white;
  border: 1px solid #ccc;
}

// Checkboxes

.custom-checkbox{
  .custom-control-label{
    border-radius: 3px;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .custom-control-input:checked ~ .custom-control-label{
    border-color: $form-check-input-active-bg;
    background-color: $form-check-input-active-bg;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpolyline points='6 12 10 16 18 8' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3e%3c/svg%3e");
  }

  .custom-control-input:disabled ~ .custom-control-label{
    background-color: $input-disabled-bg;
    cursor: not-allowed;
  }

  .custom-control-input:checked[disabled] ~ .custom-control-label{
    border-color: #ccc;
    background-color: $input-disabled-bg;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpolyline points='6 12 10 16 18 8' fill='none' stroke='%23ccc' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3e%3c/svg%3e");
  }
}


// radio

.custom-radio{
  .custom-control-label{
    border-radius: 50%;
  }

  .custom-control-input:checked ~ .custom-control-label{
    border-color: $form-check-input-active-bg; 
    background: $form-check-input-active-bg;
    box-shadow: inset 0px 0px 0px 2px $white;
  }

  .custom-control-input:disabled ~ .custom-control-label{
    background: $input-disabled-bg;
    cursor: not-allowed;
  }

  .custom-control-input:checked[disabled] ~ .custom-control-label{
    border-color: #ccc;
    background: #ccc;
    box-shadow: inset 0px 0px 0px 2px $white;
  }
}

.radio-cell {
  padding: 10px;
}
$fd-col-height:      ($font-size-base * $line-height-base) + ($font-size-base * 2);
$fd-active-bg:       darken($gray-light, 3%) !default;
$fd-active-color:    $secondary !default;
$fd-cheapest-color:  $accent !default;
$fd-highlighted-bg:  $fd-active-bg;

// 
// Flex dates hide sidebar
// 

#flexDatesContainer{
  @include media-breakpoint-up(md){
    padding-top: 2em;
  }
}

.fd-layout{
  #sidebar,
  #sidebarButton,
  .results-header{
    display: none;
  }
  #main{
    flex: 0 0 100%;
    max-width: 100%;
  }
}

// 
// Base style
//

.fd-wrapper{
  position: relative;
  display: flex;
  border: 1px solid $border-color;
}

// 
// Side
// 

.fd-side{
  width: percentage(calc(2 / 10));

  @include media-breakpoint-up(md){
    width: percentage(calc(1 / 10));
  }
}


// 
// Main
// 

.fd-main{
  width: percentage(calc(8 / 10));
  overflow-x: auto;
  overflow-y: hidden;

  @include media-breakpoint-up(md){
    width: percentage(calc(9 / 10));
  }
}

.fd-row{
  position: relative;
  white-space: nowrap;
  letter-spacing: -.31em;
  height: $fd-col-height;

  + .fd-row{
    // border-top: 1px solid $border-color;
  }
}

// controls
.fd-controls{
  background-color: $gray-light;
  height: $fd-col-height;
}

// col
.fd-col{
  background-color: #FFF;
  width: percentage(calc(1 / 2));
  height: $fd-col-height;
  letter-spacing: .01em;
  text-align: center;
  padding: theme-spaces(3);
  display: inline-block;
  border-left: 1px solid $border-color;

  &:not(.fd-head){
    border-top: 1px solid $border-color;
  }

  @include media-breakpoint-up(md){
    width: percentage(calc(1 / 7));
  }

  &.fd-highlighted{
    background-color: lighten($fd-highlighted-bg, 2%);
  }

  a{
    display: block;
    color: $gray-dark;

    @include hover-focus{
      text-decoration: none;
    }

    &.fd-cheapest{
      color: $fd-cheapest-color;
    }
  }

  &.fd-active{
    background-color: $fd-active-bg;
    a{
      color: $fd-active-color;
    }
  }
}

// head

.fd-head{
  height: $fd-col-height;
  background-color: $gray-light;
  flex-direction: column;
  padding: 12px theme-spaces(3);
  text-align: center;

  &:not(.fd-col){
    border-top: 1px solid $border-color;
  }

  .fd-dayname{
    font-size: $font-size-xs;
    line-height: 1;
    font-weight: bold;
    display: block;
  }

  &.fd-highlighted{
    background-color: $fd-highlighted-bg;
  }

  &.fd-active{
    background-color: $fd-active-bg;
    color: $fd-active-color;
  }
}


// highlight on hover
.fd-highlighted{
  background-color: rgba(#000, .07);
}

// popover

.fd-popover{

  + .fd-popover{
    margin-top: theme-spaces(2);
    padding-top: theme-spaces(2);
    border-top: 1px dashed $border-color;
  }
}


// titles
.fd-main-header{
  position: absolute;
  top: -2.5em;
  left: 10%;
  padding: .5em
}
.fd-side-header{
  position: absolute;
  left: -6.5em;
  top: 8em;
  transform: rotate(90deg);
}
.fade {
  opacity: 0;
  @include transition($transition-fade);

  &.in {
    opacity: 1;
  }
}

.collapse {
  display: none;
  &.in {
    display: block;
  }
}

tr {
  &.collapse.in {
    display: table-row;
  }
}

tbody {
  &.collapse.in {
    display: table-row-group;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  @include transition($transition-collapse);
}

.bg-primary-soft {
  background-color: rgba($primary, .8);
}

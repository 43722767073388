.result-container{
  border-top: $at-card-border-top;
  
  @each $type, $color in $type-colors{
    &.type-#{$type}{
      border-top-color: $color;
    }
  }
}

.segment-details-content{
  background-color: $gray-light;
}

.connection-info{
  background-color: $gray-light;
}

.result-widget-footer{
  border-top: 1px solid $border-color;
}
#user-account {
	.welcome {
    padding-top: 120px;
    font-size: 80px;

    @include media-breakpoint-down(lg){
      font-size: 60px;
    }

    // img {
    //   position: absolute;
    //   bottom: 0;
    //   left: 61%;
    // }
  }

  .register-stone {
    position: absolute;
    bottom: 135px;
    right: -26px;
  }
}

#recover-password, #reset-password {
  .card {
    width: 450px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.i-circle {
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  width: 8px;
}

.nav-user {

  .nav-link {
    &:hover, &:focus {
      background-color: $snow;
    }
  }

  .nav-link.active {
    background-color: $snow;

    &:before {
      content: "";
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: $primary;
      width: 4px;
      position: absolute;
    }

    .icon {
      color: $primary;
    }
  }
}

#user-home {
  .mobile-border {
    @include media-breakpoint-down(md) {
      border: none;
    }
  }
}

#user-dashboard {
  .no-trip {
    .no-trips-img {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .main-menu-item {
    color: $white; 
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: theme-spaces(3);
    margin-bottom: theme-spaces(1);
  }

  @include media-breakpoint-down(xs) {
    background-color: $white;
  }
}

.card {
  @include media-breakpoint-down(xs) {
    &.no-border-mobile {
      border: none;

      .card-header {
        border-bottom: none;
      }
    }
  }
}

.boarding-steps:hover {
  background-color: $snow;
}

.booking-board {
  .card-trip:hover {
    box-shadow: 0px -6px 12px rgba(53, 62, 68, 0.1), 0px 6px 12px rgba(53, 62, 68, 0.1);
  }

  .trip-row {
    background-color: #EDEDED;

    &.cancelled {
      background-color: #FAEBEB;
    }
  }

  .booking-row {
    background-color: #E5EFF6;
  }
}

.trip-badge {
  font-size: 11px;

  &.one-way-trip {
    background-color: #D9D9F8;
  }

  &.round-trip {
    background-color: $tertiary;
  }

  &.island-hopping-trip {
    background-color: #F8D9EB;
  }

  &.canceled-trip {
    background-color: #F8D9D9;
  }
}

.trip-conjunction {
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    top: theme-spaces(3);
    bottom: theme-spaces(3);
    left: - theme-spaces(3);
    height: auto;
    border-top: 1px solid $warning;
    border-bottom: 1px solid $warning;
    border-left: 1px solid $warning;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    top: theme-spaces(3);
    bottom: theme-spaces(3);
    right: theme-spaces(2);
    height: auto;
    border-top: 1px solid $warning;
    border-bottom: 1px solid $warning;
    border-right: 1px solid $warning;
  }
}

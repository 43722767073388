// 
// Widths
// 

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint){
    @each $width, $length in $sizes{
      .w#{$infix}-#{$width} {
        width: percentage(calc(nth($length, 1) / nth($length, 2)));
      }
      .w#{$infix}-auto {
        width: auto;
      }
      .h#{$infix}-#{$width} {
        height: percentage(calc(nth($length, 1) / nth($length, 2)));
      }
    }
  }
}


@each $size, $length in $spaces{
  .w-#{$size} {width: $length !important;}
  .h-#{$size} {height: $length !important;}
}
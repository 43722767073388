@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .float#{$infix}-left{ 
      @if $text-direction == ltr {
        float: left !important;
      } @else {
        float: right !important;
      }
    }
    .float#{$infix}-right{ 
      @if $text-direction == ltr {
        float: right !important;
      } @else {
        float: left !important;
      }
    }
    .float#{$infix}-none{
      float: none !important;
    }
  }
}
// General
.rdp {
  position: relative;

  .rdp-caption_label {
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
  }

  .rdp-day_today {
    color: $component-active-bg;
    font-weight: $font-weight-semibold;
    position: relative;

    & .rdp-today-indication {
      color: #999999;
      position: absolute;
      top: 0px;
      font-size: 8px;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
    }
  }

  .rdp-cell {
    border: 1px solid $border-color;
    background-color: $white;
  }

  .rdp-day {
    border-radius: unset;
  }

  .rdp-button:hover:not([disabled]) {
    background-color: var(--rdp-background-color);
  }

  .rdp-button:focus-visible:not([disabled]), .rdp-button:active:not([disabled]) {
    border: none;
  }

  .rdp-day_selected:not([disabled]), .rdp-day_selected:focus:not([disabled]), .rdp-day_selected:active:not([disabled]), .rdp-day_selected:hover:not([disabled]) {
    color: $white;
    background-color: theme-color('primary');
    outline: none;
    outline-offset: unset;

    & .rdp-today-indication {
      color: $white;
    }
  }

  .rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label, .rdp-dropdown:active:not([disabled]) + .rdp-caption_label {
    background-color: transparent;
    border: 0;
    border-radius: 0;
  }

  .rdp-head_cell {
    font-weight: $font-weight-normal;
  }

  @include media-breakpoint-down(xs) { 
    .rdp-months {
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: row;

      .rdp-month:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }

  .rdp-nav {
    .rdp-nav_button_previous {
      position: absolute;
      left: -2rem;
      top: -0.5rem;
    }

    .rdp-nav_button_next {
      position: absolute;
      right: -2rem;
      top: -0.5rem;
    }
  }

  &.rdp-multiple_months {
    .rdp-caption {
      position: unset;
    }

    .rdp-caption_start, .rdp-caption_end {
      .rdp-nav {
        top: 0;
        transform: none;

      }
    }

    .rdp-caption_start {
      .rdp-nav_button_previous {
        left: -0.8rem;
      }
    }

    .rdp-caption_end {
      .rdp-nav_button_next {
        right: -0.8rem;
      }
    }
  }

  .rdp-nav_button {
    border-radius: unset;
  }

}

// Affiliates page
.page-affiliates {

  @include media-breakpoint-down(xs) {
    .rdp-cell, .rdp-weeknumber, .rdp-day {
      --rdp-cell-size: 35px;
    }
  }

}

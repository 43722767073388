// Common values

// Sass list not in variables since it's not intended for customization.
$positions: static, relative, absolute, fixed, sticky;

@each $position in $positions {
  .position-#{$position} { position: $position !important; }
}


.position-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
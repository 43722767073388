#notifier-bubble{
  position: fixed;
  top: auto;
  left: auto;
  bottom: 16px;
  right: 16px;
  width: 350px;
  z-index: $zindex-modal;
}

.notifier{
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border-radius: $alert-border-radius;
  position: relative;
  @if $text-direction == ltr {
    padding-left: ($alert-icon-size + $alert-padding-y * 2);
  } @else {
    padding-right: ($alert-icon-size + $alert-padding-y * 2);
  }
  @include animate(slide-right, 0.35s, ease-out);

  &:before{
    font-family: Nucleo;
    font-size: $alert-icon-size;
    line-height: $line-height-computed;
    padding: $alert-padding-y;
    position: absolute;
    top: 0;
    @if $text-direction == ltr {
      left: 0;
    } @else {
      right: 0;
    }
  }
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.notifier-dismissible {
  padding-right: ($close-font-size + $alert-padding-y * 2);

  // Adjust close link position
  .close {
    padding: $alert-padding-y;
    color: inherit;
    position: absolute;
    top: 0;
    @if $text-direction == ltr {
      right: 0;
    } @else {
      left: 0;
    }
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $alert-variant {
  .notifier-#{$color} {
    color: $white;
    background-color: $value;

    &:before{
      @if $color == success {
        content: "\ea20";
      } @else if $color == warning {
        content: "\ea24";
      } @else if $color == danger {
        content: "\ea23";
      } @else {
        content: "\ea06";
      }
    }
  }
}

// special css styles for Toastify Library
// https://github.com/fkhadra/react-toastify
.Toastify__toast {
  padding: 16px !important;
  border-radius: 4px !important;

  &.toast-warning {
    background-color: #F0AD4E;
  }

  &.toast-warning-airtickets {
    background-color: #FF9500;
  }

  &.toast-info {
    background-color: #00A3E0;
  }

  &.toast-info-airtickets {
    background-color: #00A3E0;
  }

  &.toast-danger {
    background-color: #D9534F;
  }

  &.toast-danger-airtickets {
    background-color: #FF4A55;
  }

  &.toast-success {
    background-color: #4CAF50;
  }

  &.toast-success-airtickets {
    background-color: #87CB16;
  }

  .Toastify__progress-bar {
    background: hsla(0, 0%, 100%, 0.7);
  }
}

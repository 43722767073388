
@each $breakpoint in map-keys($grid-breakpoints){
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint){
    @each $color, $value in $background-variant {
      @include bg-variant(".bg#{$infix}-#{$color}", $value);
    }
  }
}

@each $color, $value in $theme-colors {
  .bg-#{$color}{
    background-color: $value !important;
  }
}

@each $color, $value in $theme-colors {
  .bg-#{$color}-light{
    background-color: theme-color-level($color, -10) !important;
  }
}

.bg-transparent {
  background-color: transparent !important;
}


// Cover background

.bg-cover{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-slider-cover{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

// Background overlay

.bg-overlay{
  background-color: rgba($gray-dark, 0.6);
}

.bg-overlay-mid{
  background-color: rgba($gray-dark, 0.4);
}

.bg-overlay-light{
  background-color: rgba($gray-dark, 0.1);
}

.bg-option-selected {
  background: linear-gradient(0deg, rgba($success, 0.04) 0%, rgba($success, 0.04) 100%), $white;
}
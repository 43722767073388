.cookie-consent-control {
  background: rgba($gray-dark, .8);
  color: #ffffff;
  position: fixed;
  bottom: 0;
  padding: theme-spaces(3);
  z-index: 1500;
  width: 100%;
}

.cookie-consent-control button {
  width: 100%;
}

.cookie-consent-control a {
  text-decoration: underline !important;
}

//
// Base styles
//

.loading-spinner {
  display: inline-block;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top-color: $secondary;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  pointer-events: none;
  animation: spin .6s linear infinite;

  &.primary {
    border-top-color: $primary;
  }

  &.sm {
    width: 1.25em;
    height: 1.25em;
  }
}
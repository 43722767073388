$searchMask-wrapper-padding: (theme-spaces(3));
$searchMask-wrapper-padding-bottom: (theme-spaces(3));

.searchMask-wrapper {
  .btn-search {
    height: 54px;
  }

  .slick-slider {
    padding: 0px;
  }
}

.searchMask-tab-wrapper {
  .form-control-lg,
  .btn-search.btn-lg {
    height: 56px;
    font-size: 16px;
    line-height: 38px;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }

  &.sidebar-mask {
    margin-top: 0 !important;

    .grid-item-sm,
    .grid-item-md,
    .grid-item-lg-auto {
      flex-basis: unset;
      width: 100%;
    }

    button.swapAirports {
      transform: translateY(-50%) rotate(90deg);
      top: 58px;
      right: 10px;
    }

    &:not(.home) {
      margin-top: 30px !important;
      position: relative !important;
    }

    .searchMask-wrapper {
      border-radius: 0 0 $border-radius $border-radius !important;

      .searchMask {
        &.multi-trip {
          .mask-date {
            input.form-control,
            > div {
              border-radius:  $border-radius !important;
            }
          }
        }

        .mask-from {
          border-radius: $border-radius !important;
        }

        .mask-to {
          padding-left: 16px;
          border-radius: $border-radius !important;
        }

        .mask-date {
          input.form-control,
          > div {
            border-radius: $border-radius !important;
          }
        }

        .mask-passengers {
          input,
          > div {
            border-radius: $border-radius !important;
          }
        }

        .btn-search {
          border-radius: $border-radius !important;
        }
      }

      // Destination inputs
      .destinationInput-wrapper {
        flex: unset;
        max-width: 100%;

        &.multi-trip {
          flex: unset;
          max-width: 100%;

          .mb-2 {
            margin-bottom: 8px !important;
          }
        }

        .trip-controls {
          display: flex !important;

          .trip-counter {
            display: block !important;
            width: 50% !important;
            max-width: 50% !important;
          }

          .remove-trip {
            display: block !important;
            width: 50% !important;
            text-align: right !important;

            button {
              height: auto !important;
            }
          }
        }

        .trip-counter {
          display: none !important;
        }
      }

      .remove-trip {
        display: none !important;
      }

      .multi-trip-controls {
        margin-right: -8px !important;
        margin-left: -8px !important;
      }
    }
  }

  &.only-simple-trip {
    .searchMask-wrapper {
      border-radius: $border-radius;
    }
  }

  &:not(.home) {
    .searchMask-wrapper {
      background-color: darken($primary, 10%);
      box-shadow: none;
      padding: $searchMask-wrapper-padding 0 $searchMask-wrapper-padding-bottom;

        .radio.radio-reverse {
          input[type="radio"] {
            border: 2px solid $white;
            background: darken($primary, 10%);

            &:checked{
              background: $white;
              box-shadow: inset 0 0 0 2px darken($primary, 10%);
            }
          }
        }

        .trip-counter {
          color: $white;
      }

      .remove-trip {
        button {
          color: $white;
        }
      }

      .multi-trip-controls {
        button {
          &.add-trip {
            border: 1px dashed $white;
            color: $white;
          }
        }
      }
    }
  }

  .searchMask-wrapper {
    background-color: $white;
    padding: $searchMask-wrapper-padding $searchMask-wrapper-padding $searchMask-wrapper-padding-bottom;
    border-radius: 0 0 $border-radius $border-radius;
    box-shadow: 0 2px 10px rgba($gray-dark, 0.25);

    .searchMask {
      &.multi-trip {
        .mask-date {
          input.form-control,
          > div {
            border-radius: $border-radius !important;

            @include media-breakpoint-up(lg) {
              border-radius:  0 $border-radius $border-radius 0 !important;
            }
          }
        }

        .mask-passengers {
          input,
          > div {
            border-radius: $border-radius !important;
          }
        }

        .btn-search {
          border-radius: $border-radius !important;
        }
      }

      .mask-from {
        border-radius:  $border-radius 0 0 $border-radius !important;

        @include media-breakpoint-down(xs) {
          border-radius: $border-radius !important;
        }
      }

      .mask-to {
        padding-left: 24px;

        @include media-breakpoint-down(xs) {
          padding-left: 16px;
          border-radius: $border-radius !important;
        }
      }

      .mask-date {
        input.form-control,
        > div {

          @include media-breakpoint-down(sm) {
            border-radius: $border-radius !important;
          }
        }
      }

      .mask-passengers {
        input,
        > div {
          @include media-breakpoint-down(md) {
            border-radius:  0 $border-radius $border-radius 0 !important;
          }

          @include media-breakpoint-down(sm) {
            border-radius: $border-radius !important;
          }
        }
      }

      .btn-search {
        border-radius:  0 $border-radius $border-radius 0 !important;

        @include media-breakpoint-down(md) {
          border-radius: $border-radius !important;
        }
      }
    }

    // Destination inputs
    .destinationInput-wrapper {
      @include make-col-ready;
      padding: 0;

      @include media-breakpoint-up(lg) {
        flex: 0 0 55%;
        max-width: 55%;

        &.multi-trip {
          flex: 0 0 55%;
          max-width: 55%;

          .mb-2 {
            margin-bottom: 16px !important;
          }
        }
      }
    }

      .trip-controls {
        display: none;

        @include media-breakpoint-down(lg) {
          display: flex;
        }

        .trip-counter {
          @include media-breakpoint-down(lg) {
            display: block;
            width: 50%;
            max-width: 50%;
          }
        }

        .remove-trip {
          @include media-breakpoint-down(lg) {
            display: block;
            width: 50%;
            text-align: right;

            button {
              height: auto;
            }
          }
        }
      }

      .trip-counter {
        max-width: 30px;
        align-self: center;
        color: $primary;
        font-weight: bold;

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }

    .remove-trip {
      text-align: center;
      width: 30px;
      font-size: 18px;

      @include media-breakpoint-down(lg){
        display: none;
      }

      button {
        padding: 0;
        border: 0;
        background-color: transparent;
        appearance: none;
        color: $gray-mid;
        height: 54px;
        text-align: right;

        &:disabled {
          color: $gray-light;
        }
      }
    }

    .multi-trip-controls {
      margin-right: 22px;
      margin-left: 22px;
      margin-top: 8px;

      @include media-breakpoint-down(lg) {
        margin-right: -8px;
        margin-left: -8px;
      }

      button {
        &.add-trip {
          background: transparent;
          border: 1px dashed $primary;
          border-radius: $border-radius;
          color: $primary;
          font-size: 16px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .popolar-port-overlay {
    @include hover {
      background-color: $gray-light;
    }
  }
}

button.swapAirports {
  padding: 0;
  appearance: none;
  position: absolute;
  z-index: 1;
  top: 50%;
  @if $text-direction == ltr {
    right: -16px;
  } @else {
    left: -16px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  color: $text-muted;
  line-height: 20px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: $gray-light;
  border: 1px solid darken($gray-light, 10%);
  transform: translateY(-50%);

  @include media-breakpoint-down(xs) {
    transform: translateY(-50%) rotate(90deg);
    top: 63px;
    right: 10px;
    width: 48px;
    height: 48px;
  }
}

.autosuggestLoading {
  position: absolute;
  top: 50%;
  @if $text-direction == ltr {
    right: 1px;
  } @else {
    left: 1px;
  }
  color: $text-muted;
  padding: $input-padding-y-lg $input-padding-x-lg;
  transform: translateY(-50%);
  background-color: #FFF;
  z-index: 2;
}

// Search mask

.smb-width {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-add-one {
  background-color: $white;
  border: 2px solid $primary;
  color: $primary;
  line-height: 32px;

  &:hover {
    background-color: $primary;
    color: $white;
    border-color: $primary;
  }

  &:disabled {
    color: $white;
    background-color: $gray-light;
    border-color: $gray-light;

    &:hover {
      background-color: $gray-light;
      border-color: $gray-light;
    }
  }
}

.map-search {
  .leaflet-tooltip {
    pointer-events: auto;
    top: -16px;
    opacity: 1 !important;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: $white;
    box-shadow: 0 2px 10px rgba($gray-dark, 0.25);

    &:before {
      content: none;
    }

    &:hover {
      background-color: lighten($primary, 40%);
    }
  }
}

.wave-line{
  width: 100%;
  position: relative;
  top: 10px;
  height: 30px;
  opacity: .3;
  background:
  radial-gradient(circle 15px at 50% -10px, rgb(0, 204, 177) calc(99% - 1px), $white calc(101% - 1px) 99%, rgb(0, 204, 177) 101%) 0 0 / 18px 5px repeat-x;
}


#popular-countries-slider {

  .slick-list {
    overflow: unset;
  }

  .slick-track {
    display: flex;
  }
}

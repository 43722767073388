#trengo-web-widget {
  .TrengoWidgetLauncher__iframe {
    bottom: 50px;
    z-index: 300;
  }

  .TrengoOnlineStatus__iframe {
    bottom: 35px;
    z-index: 300;
  }
}
